import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '@core/services/auth.service';
import { AlertService } from '@core/services/alert.service';
import { UtilityService } from '@core/services/utility.service';
// import { CookiesService } from '@core/services/cookies.service';

@Injectable({
    providedIn: 'root'
})
export class PreventRouteGuard implements CanActivate/* , CanActivateChild, CanLoad */ {

    constructor(
        private _router: Router,
        private authService: AuthService,
        private _alert: AlertService,
        private _utility:UtilityService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return new Promise((resolve: Function, reject: Function) => {
            let isUserLoggedIn = this.authService.checkIfLoggedIn();
            if (isUserLoggedIn && (state.url.includes('/auth/') || state.url.includes('/dealer/registration'))) {
                if (state.url.includes('/auth/')) {
                    this._router.navigateByUrl(this._router.url);
                } else {
                    this._alert.warn((this._utility.getTranslatedMessage('toastrMessages','Pleaselogoutyourselftoaccessit')),{ timer: 3000 })
                    // ('Please logout your self to access it.',{ timer: 3000 })
                }
            } else {
                resolve(true)
            }
        })
    }
}