<form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <div>
        <div class="mb-2">
            <input placeholder="First Name" type="text" name="firstName" formControlName="firstName" required>
            <div *ngIf="form.controls['firstName'].hasError('required')">This field is required</div>
        </div>
    </div>
    <div>
        <div>
            <input placeholder="Last Name" type="text" name="lastName" formControlName="lastName" required>
            <div *ngIf="form.controls['lastName'].hasError('required')">This field is required</div>

        </div>
    </div>
    <br>
    <div>
        <button color="secondary" type="button" (click)="cancel()">cancel</button>
        <button color="primary" type="submit">save</button>
    </div>

</form>