import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLimitInput]'
})
export class LimitInputDirective implements OnInit {
  @Input() maxLimit: any = 4;
  @Input() value!: 'text' | 'space' | 'address' | 'number' | '';
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    //this.renderer.setAttribute(this.el.nativeElement, 'maxLength', this.maxLimit.toString());
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: any): void {
    const value = event.target.value;
    let regex = new RegExp("^[a-zA-Z0-9]+$");  // global validation
    /* check limit */
    if (value.toString().length >= parseInt(this.maxLimit)) {
      event.preventDefault();
      return;
    }

    if (this.value == 'text') {
      regex = new RegExp("^[a-zA-Z0-9 ,'-.@/_]+$");
    }

    if (this.value == 'space') {
      regex = new RegExp("^[a-zA-Z0-9 ]+$");
    }

    if (this.value == 'address') {
      regex = new RegExp("^[a-zA-Z0-9 ,'-.@/]+$");
    }

    let str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return;
    }

    if (this.value == 'number') {
      let charCode = (event.which) ? event.which : event.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return;
      }
    }
  }

}
