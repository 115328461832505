import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddCardRoutingModule } from './add-card-routing.module';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowErrorsModule } from '../show-errors/show-errors.module';
import { CoreDirectivesModule } from '@core/directives/core-directives.module';
import { AddCardComponent } from './add-card.component';
import { DeactivateGuard } from '@views/common/payment/payment-deactivate-guard';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [AddCardComponent],
  imports: [
    CommonModule,
    AddCardRoutingModule,
    PortalModule,
    ReactiveFormsModule,
    ShowErrorsModule,
    CoreDirectivesModule,
    FormsModule,
    TranslateModule
  ],
  exports: [AddCardComponent],
  providers: [DeactivateGuard],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class AddCardModule { }
