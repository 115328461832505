import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '@core/config';
import { BaseService } from '@core/services/base.service';
import { CacheDataService } from '@core/services/cache-data.service';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditAddressService {

  serverUrl: any;
  httpClient: any;

  constructor(
    private _api: BaseService,
    private _cache: CacheDataService
  ) { }

  async getState(country: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this._api.get(api.geo.state.replace(':country_id', country)).pipe(delay(1000)).subscribe((res: any) => {
        if (res) {
          // alert("data fetched");
          // console.log("state list >>", res);
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  submitAddressUpdateForm(formData: any, id): Promise<any> {
    console.log("Formdata : ", formData);
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'multipart/form-data');
      headers = headers.append('enctype', 'multipart/form-data');

      this._api.post(api.address.update_address.replace(':address_id', id), formData, { headers: headers }).subscribe((res: any) => {
        console.log('Address update form RES ', res);
        if (res && res.status == "error") {
          reject(res)
        } else {
          resolve(res)
        }
      })
    });
  }

  submitAddressCreateForm(formData: any): Promise<any> {
    console.log("Formdata : ", formData);
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'multipart/form-data');
      headers = headers.append('enctype', 'multipart/form-data');

      this._api.post(api.address.create_address, formData, { headers: headers }).subscribe((res: any) => {
        console.log('Address update form RES ', res);
        if (res && res.status == "error") {
          reject(res)
        } else {
          resolve(res)
        }
      })
    });
  }
}
