import { NgModule } from '@angular/core';
import { ImageCropperComponent } from './component/image-cropper.component';
import { ImageCropperMainComponent } from './image-cropper-main/image-cropper-main.component';
// import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [],
  declarations: [ImageCropperMainComponent, ImageCropperComponent],
  exports: [ImageCropperMainComponent],
})
export class ImageCropperModule {}
