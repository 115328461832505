import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [PaginationComponent],
  bootstrap: [PaginationComponent],
  imports: [CommonModule,
    TranslateModule],
  exports: [PaginationComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class PaginationModule { }
