import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';
import { UtilityService } from '@core/services/utility.service';


@Component({
  selector: 'golfed-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  showHideButton: boolean = false;
  constructor(
    private loaderService: LoaderService,
    private _utilityService: UtilityService,
    private _cdr: ChangeDetectorRef
  ) {
    this.loaderService.isLoading.subscribe((res) => {
      this.showHideButton = res;
      _cdr.detectChanges();
    })
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.showHideButton = true;
    }, 30000);
  }

  moveToDashboard() {
    this.loaderService.hide();
    this._utilityService._navigate('dashboard')
  }
}
