import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SizeChartModalRoutingModule } from './size-chart-modal-routing.module';
import { PortalModule } from '@angular/cdk/portal';
import { ReactiveFormsModule } from '@angular/forms';
import { ShowErrorsModule } from '../show-errors/show-errors.module';
import { CoreDirectivesModule } from '@core/directives/core-directives.module';
import { SizeChartModalComponent } from './size-chart-modal.component';


@NgModule({
  declarations: [SizeChartModalComponent],
  imports: [
    CommonModule,
    SizeChartModalRoutingModule,
    PortalModule,
    ReactiveFormsModule,
    CoreDirectivesModule
  ],
  exports: [SizeChartModalComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SizeChartModalModule { }
