import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowErrorsModule } from './show-errors/show-errors.module';
import { CoreDirectivesModule } from '@core/directives/core-directives.module';
import { ImageCropperModule } from './image-cropper/image-cropper.module';
import { CdkCustomMdlModule } from '@features/customCDK/cdkCustomMdl/cdk-custom-mdl.module';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageUploadModule } from './image-upload/image-upload.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { PaginationModule } from './pagination/pagination.module';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { EditAddressModule } from './edit-address/edit-address.module';
import { ImageUploaderModule } from './image-uploader/image-uploader.module';
import { MatCardModule } from '@angular/material/card';
import { SizeChartModalComponent } from './size-chart-modal/size-chart-modal.component';
import { SizeChartModalModule } from './size-chart-modal/size-chart-modal.module';
import { MatSelectModule } from '@angular/material/select';
import { NgImageSliderComponent, NgImageSliderModule } from 'ng-image-slider';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AddCardComponent } from './add-card/add-card.component';
import { AddCardModule } from './add-card/add-card.module';
import { MatIconModule } from '@angular/material/icon';
import { ProgressStepModule } from './progress-step/progress-step.module';
import { ProgressStepperModule } from './progress-stepper/progress-stepper.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../app.module';
// import { MatButtonModule } from '@angular/material/button';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ShowErrorsModule,
    CoreDirectivesModule,
    CdkCustomMdlModule,
    ImageCropperModule,
    MatTooltipModule,
    MatTabsModule,
    ImageUploadModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    CdkAccordionModule,
    MatExpansionModule,
    EditAddressModule,
    ImageUploaderModule,
    MatCardModule,
    SizeChartModalModule,
    MatSelectModule,
    NgImageSliderModule,
    // NgImageSliderModule,
    SlickCarouselModule,
    AddCardModule,
    MatIconModule,
    ProgressStepModule,
    ProgressStepperModule,
    NgxSkeletonLoaderModule,
    NgxPaginationModule,
    MatCheckboxModule,
    TranslateModule
    // MatButtonModule,
    // MatFormFieldModule,
    // MatInputModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ShowErrorsModule,
    CoreDirectivesModule,
    CdkCustomMdlModule,
    ImageCropperModule,
    MatTooltipModule,
    MatTabsModule,
    ImageUploadModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    PaginationModule,
    CdkAccordionModule,
    MatExpansionModule,
    EditAddressModule,
    ImageUploaderModule,
    MatCardModule,
    SizeChartModalModule,
    MatSelectModule,
    // NgImageSliderModule,
    SlickCarouselModule,
    NgImageSliderModule,
    AddCardModule,
    MatIconModule,
    ProgressStepModule,
    ProgressStepperModule,
    NgxSkeletonLoaderModule,
    NgxPaginationModule,
    MatCheckboxModule,
    TranslateModule
    // MatButtonModule,
    // MatFormFieldModule,
    // MatInputModule
  ],
  providers: [
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        // separatorKeyCodes: [COMMA, SPACE]
      }
    }
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
