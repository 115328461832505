import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isLoading = new EventEmitter<boolean>();
  doNotShowLoader: boolean = false;

  constructor() { }
  show() {
    if (!this.doNotShowLoader) {
      this.isLoading.next(true);
    }
  }
  hide() {
    this.isLoading.next(false);
  }

  hidden(flag: boolean) {
    this.doNotShowLoader = flag;
  }
  
}
