<!-- <input type="file" (change)="fileChangeEvent($event)" />
<br />
<br />
<button (click)="rotateLeft()">Rotate left</button>
<button (click)="rotateRight()">Rotate right</button>
<button (click)="flipHorizontal()">Flip horizontal</button>
<button (click)="flipVertical()">Flip vertical</button>
<br />
<br />
<button (click)="toggleContainWithinAspectRatio()">
  {{
    containWithinAspectRatio
      ? "Fill Aspect Ratio"
      : "Contain Within Aspect Ratio"
  }}
</button>
<button (click)="resetImage()">Reset image</button>
<br />
<br />
<input
  [(ngModel)]="rotation"
  placeholder="Rotation"
  type="number"
  (keyup)="updateRotation()"
/>
<button (click)="zoomOut()">Zoom -</button>
<button (click)="zoomIn()">Zoom +</button> -->

<div>
  <image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [containWithinAspectRatio]="containWithinAspectRatio"
    [aspectRatio]="4 / 3"
    [resizeToWidth]="256"
    [cropperMinWidth]="128"
    [onlyScaleDown]="true"
    [roundCropper]="false"
    [canvasRotation]="canvasRotation"
    [transform]="transform"
    [alignImage]="'center'"
    [style.display]="showCropper ? null : 'none'"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady($event)"
    (loadImageFailed)="loadImageFailed()"
  ></image-cropper>
</div>
<div class="my-3">
  <img
    [src]="croppedImage"
    [style.border]="croppedImage ? '1px solid black' : 'none'"
  />
</div>
<div class="text-center">
  <button type="button" class="btn btn-primary" (click)="Crop()">Crop</button>
  <button type="button" class="btn btn-info" (click)="cancelCrop()">Close</button>
</div>
