import { Injectable } from '@angular/core';
import { config } from '@core/config'
import * as CryptoJS from "crypto-js";


@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, config.encryptionKey).toString();
  }

  decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, config.encryptionKey).toString(CryptoJS.enc.Utf8);
  }
}
