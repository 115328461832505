import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AlertService } from '@core/services/alert.service';
import { UtilityService } from '@core/services/utility.service';

@Component({
  selector: 'image-uploader',
  template: `  
  <!-- <input
  *ngIf="showRemoveButton && this.imageSrc != ''"
  type="button"
  value="Remove"
  (click)="resetInput()"/> -->
<!-- <img (click)="resetInput()" *ngIf="showRemoveButton && this.imageSrc != ''" type="button"
  value="Remove" src="../../../../../assets/images/closeIcon.svg" alt="" class="closeIcon"> -->
  <span (click)="resetInput()" *ngIf="showRemoveButton && this.imageSrc != ''" type="button"
  value="Remove" class="closeIcon">X</span>
    <label
      class="uploader"
      ondragover="return false;"
      [ngClass]="{ show: shouldShow, hide: !shouldShow }"
      [class.loaded]="loaded"
      [style.outlineColor]="dragging ? activeColor : baseColor"
      (dragenter)="handleDragEnter()"
      (dragleave)="handleDragLeave()"
      (drop)="handleDrop($event)"
    >
      <i
        class="icon icon-upload glyphicon glyphicon-upload"
        [style.color]="
          dragging
            ? imageSrc.length > 0
              ? overlayColor
              : activeColor
            : imageSrc.length > 0
            ? overlayColor
            : baseColor
        "
      ></i>
      <img
        [src]="imageSrc || defaultImage"
        (load)="handleImageLoad()" [ngClass]="{'defaultImg' : imageSrc==''}"
        [class.loaded]="imageLoaded"
        style="height: 150px;"
      />

      <input
        type="file"
        name="file"
        #fileInput
        #takeInput
        accept="image/x-png, image/jpg, image/jpeg"
        (change)="handleInputChange1($event)"
      />
    </label>
  `,
  styleUrls: ['./image-uploader.component.scss'],
  inputs: ['activeColor', 'baseColor', 'overlayColor', 'showRemoveButton', 'defaultImage', 'setSavedImage'],
})
export class ImageUploaderComponent {
  @Input() compareWithFile = null;
  @ViewChild("takeInput", { static: false }) InputVar: ElementRef;
  @Output() handleInputChange = new EventEmitter();
  @Output() handleInputRemove = new EventEmitter();
  @ViewChild('fileInput', { static: false })
  fileInputVar: ElementRef;
  buttonOpacity = 0.1;
  shouldShow = true;
  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  showRemoveButton: boolean;
  defaultImage: any = "";
  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '';
  setSavedImage: string = '';
  imageType: any;
  fileForCompare: File;
  // compareWithFile: File;
  constructor(private _alert: AlertService, private _utility:UtilityService) { }

  ngOnInit(): void {
    if (this.setSavedImage != '' && this.setSavedImage != null) {
      this.imageSrc = this.setSavedImage;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let data: any = changes;
    if (data?.compareWithFile?.currentValue != undefined && data?.compareWithFile?.currentValue != null) {
      this.fileForCompare = data?.compareWithFile?.currentValue;
    }
    else {
      this.fileForCompare = null;
    }
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange1(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange1(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    let allowableFileType = ['image/png', 'image/jpeg', 'image/jpg'];
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG"];
    var fileExtension = file.name.split('.').pop();
    let filesize = file.size;
    let filetotalsize: any = +(filesize / (1024 * 1024));

    if (allowableFileType.includes(file.type) && this.isInArray(allowedExtensions, fileExtension)) {
      if (filetotalsize <= 7) {
        if (this.fileForCompare != undefined && this.fileForCompare != null) {
          if (file.name != this.fileForCompare.name) {
            var reader = new FileReader();
            this.loaded = false;
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsDataURL(file);
            this.handleInputChange.emit(file);
          } else {
            this._alert.error(this._utility.getTranslatedMessage('toastrMessages','Pleaseselectdifferentimage'));
            console.warn('Please select a different Image!');
            e.preventDefault();
            this.InputVar.nativeElement.value = "";
            return false;
          }
        }
        else {
          var reader = new FileReader();
          this.loaded = false;
          reader.onload = this._handleReaderLoaded.bind(this);
          reader.readAsDataURL(file);
          this.handleInputChange.emit(file);
        }

      } else {
        this._alert.error(this._utility.getTranslatedMessage('toastrMessages','Imagesizeshouldbelessthen6MB'));
        console.warn('Image size exceeds, maximum allowable size of 6MB!');
        e.preventDefault();
        this.InputVar.nativeElement.value = "";
        return false;
      }
    } else {
      this._alert.info(this._utility.getTranslatedMessage('toastrMessages','OnlyJPEGJPGandPNGimagesallowed'));
      e.preventDefault();
      this.InputVar.nativeElement.value = "";
      return false;
    }
  }

  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  _handleReaderLoaded(e) {
    var reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
  }

  resetInput() {
    this.fileInputVar.nativeElement.value = '';
    this.imageSrc = '';
    this.handleInputRemove.emit(true);
    this.handleInputChange.emit(null);
    this.setSavedImage = '';
  }
}
