import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@core/services/alert.service';
import { UtilityService } from '@core/services/utility.service';
import { AuthAPIService } from '@views/Auth/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  verifyToken: any;

  constructor(
    private _auth: AuthAPIService,
    private route: ActivatedRoute,
    private _alert: AlertService,
    public _utility: UtilityService
  ) {
    this.route.params.subscribe((value) => {
      this.verifyToken = value.token
      console.log('reset-password-token', this.verifyToken)
    });
  }

  ngOnInit(): void {
    if (this.verifyToken) {
      this.verifyUserMail()
    }
  }

  verifyUserMail() {
    this._auth.verfiyEmail(this.verifyToken)
    .then((res) => {
      console.log('mail res >> ', res);
      this._alert.info(this._utility.getTranslatedMessage('toastrMessages','Emailverifiedsuccessfully'), {timer: 8000})
      // ('Email verified successfully!', {timer: 8000})
      setTimeout(() => {
        window.close();
      }, 8500);
    })
    .catch((error) =>{
      // error.message
      this._alert.error(this._utility.getTranslatedMessage('toastrMessages','Emailverificationlink'), {timer: 8000})
      // ('Email verification link is not valid. Please contact support team.', {timer: 8000});
      setTimeout(() => {
        window.close();
      }, 8500);
    })
  }

}
