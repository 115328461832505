<div class="main-content">
    <!-- Top navbar -->
    <app-header></app-header>

    <!-- Sidenav -->
    <!-- <app-sidebar></app-sidebar> -->

    <!-- Pages -->
    <!-- <div> -->
        <router-outlet></router-outlet>
    <!-- </div> -->

    <div class="container-fluid">
        <app-footer></app-footer>
    </div>
</div>