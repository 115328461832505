import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AddressDetails } from '@core/interfaces/dealer.model';
import { AlertService } from '@core/services/alert.service';
import { CacheDataService } from '@core/services/cache-data.service';
import { UtilityService } from '@core/services/utility.service';
import { TemplateModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/template-modal.ref';
import {
  CustomOverlayConfig,
  TemplateModalService,
} from '@features/customCDK/cdkCustomMdl/services/template-modal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditAddressService } from './edit-address.service';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss'],
})
export class EditAddressComponent implements OnInit {
  @ViewChild('modalTemplate') modalTemplate!: TemplatePortal<any>;
  private _unsubscribeAll: Subject<any> = new Subject();
  private templateRef!: TemplateModalOverlayRef;
  @Output() addressDetailsSubmit = new EventEmitter();
  customConfig!: CustomOverlayConfig;
  @Input() addressDetails = null;
  addressModal: any;
  addressInfoForm: UntypedFormGroup;
  countryList: any;
  stateList: any;
  selectedCountry: any;
  isEditAddress: boolean = false;

  constructor(
    private _TemplateModalService: TemplateModalService,
    private _fb: UntypedFormBuilder,
    private _alert: AlertService,
    private _cache: CacheDataService,
    private _utility: UtilityService,
    private _editAddress: EditAddressService
  ) {
    _cache.masterDownloadData
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((mData: any) => {
        if (mData) {
          this.countryList = mData.all_countries;
          if (this.countryList != null && this.countryList != undefined) {
            if (this.addressModal != null && this.addressModal != undefined) {
              this.setDefaultValues(this.addressModal);
              this.isEditAddress = true;
            }
          }
        }
      });
    this.initializeForm();
  }

  get addInfoForm() {
    return this.addressInfoForm.controls;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    let data: any = changes.addressDetails.currentValue;
    if (data != undefined && data != null) {
      this.addressModal = data;
      this.setDefaultValues(data);
      this.isEditAddress = true;
    }
  }

  initializeForm() {
    this.addressInfoForm = this._fb.group({
      address_line_1: ['', [Validators.required]],
      address_line_2: ['', Validators.required],
      city_name: ['', [Validators.required]],
      zip_code: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(8)],
      ],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
    });
  }

  openEditAddressModal() {
    this.addressInfoForm.reset();
    if (this.addressModal != null && this.addressModal != undefined) {
      this.setDefaultValues(this.addressModal);
    }
    this.templateRef = this._TemplateModalService.open(
      this.modalTemplate,
      {},
      this.customConfig
    );
  }

  setDefaultValues(data) {
    this.addressInfoForm.reset();
    this.stateList = [];
    this.getStateList(data.country, data.state);
    this.addressInfoForm.patchValue({
      address_line_1: data.address_line_1,
      address_line_2: data.address_line_2,
      city_name: data.city_name,
      zip_code: data.zip_code,
    });
    if (this.countryList != undefined && this.countryList != null) {
      this.addressInfoForm.patchValue({
        country: data.country,
      });
    }
    console.log('Address dtails', this.addressInfoForm.value);
  }

  submitAddressForm($event?: CustomOverlayConfig) {
    if (this.addressInfoForm.invalid) {
      this._utility.markFormGroupTouched(this.addressInfoForm);
    } else {
      var addressDetails = this.addressInfoForm.value;
      if (this.addressModal != null && this.isEditAddress) {
        addressDetails.id = this.addressModal.id;
        addressDetails.type = this.addressModal.type;
        this._editAddress
          .submitAddressUpdateForm(addressDetails, this.addressModal.id)
          .then((result: any) => {
            if (result.status == 'success') {
              this._alert.success(this._utility.getTranslatedMessage('toastrMessages','Addressupdatedsuccessfully'))
              // ('Address updated successfully!');
              this.addressDetailsSubmit.emit(addressDetails);
              this.closeTemplateModal($event);
            } else {
              this._alert.error(this._utility.getTranslatedMessage('toastrMessages','UnabletoupdateAddress'))
              // ('Unable to update Address!!');
            }
          })
          .catch((err) => {
            this._alert.error(this._utility.getTranslatedMessage('toastrMessages','UnabletoupdateAddress'))
            // ('Unable to update Address!!');
          });
      } else {
        addressDetails.type = 'residential';
        this._editAddress
          .submitAddressCreateForm(addressDetails)
          .then((result: any) => {
            if (result.status == 'success') {
              this._alert.success(this._utility.getTranslatedMessage('toastrMessages','Addressupdatedsuccessfully'))
              // ('Address added successfully!');
              this.addressDetailsSubmit.emit(addressDetails);
              this.closeTemplateModal($event);
            } else {
              this._alert.error(this._utility.getTranslatedMessage('toastrMessages','UnabletoupdateAddress'))
              // ('Unable to added Address!!');
            }
          })
          .catch((err) => {
            this._alert.error(this._utility.getTranslatedMessage('toastrMessages','UnabletoupdateAddress'))
            // ('Unable to update Address!!');
          });
      }
    }
  }

  closeTemplateModal($event?: CustomOverlayConfig) {
    this.templateRef.close(this.customConfig);
  }

  PickCountry(countryName: any, state?: any) {
    this.selectedCountry = this._utility.findObjectValue(
      this.countryList,
      ({ name }) => name == countryName
    )?.id;
    this.getStatesfromCountry(state);
  }

  getStateList(country, state) {
    if (country != null && country != undefined && country != '') {
      if (this.stateList != undefined && this.stateList != null) {
        if (this.stateList.length == 0) {
          this.PickCountry(country, state);
        } else {
          var data = this.stateList?.find((x) => x.name == state);
          if (data == undefined || data?.length == 0 || data == null) {
            this.PickCountry(country, state);
          }
        }
      } else {
        this.PickCountry(country, state);
      }
    }
  }

  getStatesfromCountry(selectedState) {
    this._editAddress.getState(this.selectedCountry).then((res: any) => {
      if (selectedState != undefined && selectedState != '') {
        this.manageStateList(res, selectedState);
      } else {
        this.stateList = res;
      }
      if (
        selectedState != null &&
        selectedState != undefined &&
        selectedState != ''
      ) {
        this.addressInfoForm.patchValue({
          state: selectedState,
        });
      }
    });
  }

  manageStateList(data, stateName) {
    if (this.stateList != undefined) {
      if (this.stateList.length == 0) {
        this.stateList = data;
      } else {
        var data = this.stateList?.find((x) => x.name == stateName);
        if (data == undefined || data?.length == 0) {
          this.stateList = data;
        }
      }
    } else {
      this.stateList = data;
    }
  }
}
