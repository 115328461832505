import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { CustomOverlayConfig } from './template-modal.service';

@Injectable()
export class CommonFunctionsService {

    constructor() { }
    setPositionStrategy(customConfig: CustomOverlayConfig, positionStrategy: GlobalPositionStrategy) {
        if (customConfig.size) {
            positionStrategy = positionStrategy
                .width(customConfig.size.width)
                .height(customConfig.size.height);
        }

        if (customConfig.isCentered) {
            positionStrategy = positionStrategy
                .centerHorizontally()
                .centerVertically();
        }

        if (customConfig.customOverlayPosition?.topLeft) {
            let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.topLeft);

            positionStrategy = positionStrategy
                .top(posVal[0])
                .left(posVal[1]);
        }

        if (customConfig.customOverlayPosition?.topCenter) {
            let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.topCenter);


            positionStrategy = positionStrategy
                .top(posVal[0])
                .centerHorizontally(posVal[1])
        }

        if (customConfig.customOverlayPosition?.topRight) {
            let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.topRight);

            positionStrategy = positionStrategy
                .top(posVal[0])
                .right(posVal[1]);
        }

        if (customConfig.customOverlayPosition?.leftCenter) {
            let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.leftCenter);

            positionStrategy = positionStrategy
                .centerVertically(posVal[0])
                .left(posVal[1]);
        }

        if (customConfig.customOverlayPosition?.rightCenter) {
            let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.rightCenter);

            positionStrategy = positionStrategy
                .centerVertically(posVal[0])
                .right(posVal[1]);
        }

        if (customConfig.customOverlayPosition?.leftBottom) {
            let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.leftBottom);

            positionStrategy = positionStrategy
                .bottom(posVal[0])
                .left(posVal[1]);
        }

        if (customConfig.customOverlayPosition?.bottomCenter) {
            let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.bottomCenter);

            positionStrategy = positionStrategy
                .bottom(posVal[0])
                .centerHorizontally(posVal[1]);
        }

        if (customConfig.customOverlayPosition?.bottomRight) {
            let posVal: string[] = this.setMdlPosition(customConfig.customOverlayPosition?.bottomRight);

            positionStrategy = positionStrategy
                .bottom(posVal[0])
                .right(posVal[1]);
        }
        return positionStrategy;
    }

    setMdlPosition(posVal: [string, string] | boolean): string[] {
        let firstValue: string = '0px';
        let SecondValue: string = '0px';
        if (posVal.constructor === Array) {
            firstValue = posVal[0];
            SecondValue = posVal[1];
        }
        return [firstValue, SecondValue]
    }

    setMdlAnimation(customConfig: any, custPanelClass: any) {
        let panelCls: any = custPanelClass;
        
        if (customConfig?.isAnimation) {
            let updateVal: any = ['animate__animated', custPanelClass]
            let customVal: any = customConfig?.isAnimation?.inAnimationStyle;
            if (customVal) {
                if (customVal.constructor === Array) {
                    customVal.forEach(element => {
                        updateVal.push('animate__' + element)
                    });
                } else {
                    updateVal.push('animate__' + customVal)
                }
            }
            panelCls = updateVal;

        }
        return panelCls;
    }
}
