export class Alert {
    id?: string;
    type: AlertType = 2;
    message?: string;
    autoClose?: boolean;
    remove: number = 3000;
    keepAfterRouteChange?: boolean;
    fade?: boolean;
    constructor(init?: Partial<Alert>) {
        Object.assign(this, init);
    }
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}

export interface AlertOption {
    icon?: 'warning' | 'error' | 'success' | 'info' | 'question';
    showConfirmButton?: boolean;
    confirmButtonText?: string;
    timer?: number;
    timerProgressBar?: boolean;
    allowOutsideClick?: boolean;
    showTextButton?: string;
    isCallback?: boolean
}