import { Component } from '@angular/core';
// import { MockTask } from '../mocks/mocktasks';
// import { ProgressStepComponent } from '../progress-step/progress-step.component';

@Component({
  selector: 'progress-stepper',
  templateUrl: './progress-stepper.component.html',
  styleUrls: [ './progress-stepper.component.scss' ]
})
export class ProgressStepperComponent  {
}