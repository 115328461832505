import { CommonModule } from '@angular/common';
import { ImageUploaderComponent } from './image-uploader.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';



@NgModule({
  declarations: [ImageUploaderComponent],
  bootstrap: [ImageUploaderComponent],
  imports: [
    CommonModule
  ],
  exports: [ImageUploaderComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class ImageUploaderModule { }
