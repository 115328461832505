import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '@core/config';
import { BaseService } from '@core/services/base.service';
import { TranslateService } from '@ngx-translate/core';
import { rejects } from 'assert';
import { resolve } from 'dns';
import { promise } from 'protractor';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private _api: BaseService, private _http: HttpClient,private _translateService:TranslateService) { }

  getPlateformCommission(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.product.productCommission).subscribe((res: any) => {
        if (res instanceof HttpErrorResponse) {
          reject(res)
        } else {
          resolve(res)
        }
      })
    });
  }

  public getOrdersList(page: number = 1, limit: number = 20, sortBy: string = 'created_at', sortOrder: string = 'DESC', search: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.order.myOrders + `?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`).subscribe((res: any) => {
        if (res && res.data) {
          // console.log('data >>', res )
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  public getProductsList(page: number = 1, limit: number = 20, sortBy: string = 'created_at', sortOrder: string = 'DESC', search: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.product.myProducts + `?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`).subscribe((res: any) => {
        if (res && res.data) {
          // console.log('data >>', res )
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  public getProductListFilter(page: number = 1, limit: number = 20, sortBy: string = 'created_at', sortOrder: string = 'DESC', filters: any, search: string = '', timeUnit: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.product.myProducts + `?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}&category_id=${filters.category_id}&brand_id=${filters.brand_id}&gender=${filters.gender}&search=${search}&timeUnit=${timeUnit}`).subscribe((res: any) => {
        if (res && res.data) {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  uploadFile(formData: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('enctype', 'multipart/form-data');
    return new Promise((resolve, reject) => {
      this._api.post(api.pseller.imageData, formData, { headers: headers }).subscribe((res: any) => {
        console.log('response==', res);
        if (res && res.status == "success") {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  public getSellerDealerOrders(page: number = 1, limit: number = 20, sortBy: string = 'created_at', sortOrder: string = 'DESC', search: string = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.order.sellerDealerOrders + `? page = ${page} & limit=${limit} & sortBy=${sortBy} & sortOrder=${sortOrder} & search=${search}`).subscribe((res: any) => {
        if (res && res.data) {
          // console.log('data >>', res )
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  updateStatus(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.post(api.order.reviewRequest, data).subscribe((res: any) => {
        console.log("Review request api res : ", res);
        if (res && res.statusCode == 200 && res.status == 'success') {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  shippingStatus(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.post(api.order.shipping.replace(':id', id), {}).subscribe((res: any) => {
        resolve(res);
      });
    })
  }

  submitProductCreateForm(formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'multipart/form-data');
      headers = headers.append('enctype', 'multipart/form-data');

      this._api.post(api.product.create, formData, { headers: headers }).subscribe((res: any) => {
        console.log('submitProductCreateForm RES ', res);
        if (res && res.status == "error") {
          reject(res)
        } else {
          resolve(res)
        }
      })
    });
  }

  uploadCsvFile(formData: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('enctype', 'multipart/form-data');
    return new Promise((resolve, reject) => {
      this._api.post(api.product.createByCsv, formData, { headers: headers }).subscribe((res: any) => {
        console.log('response==', res);
        if (res && res.status == 200) {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  uploadExcelFile(formData: any): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('enctype', 'multipart/form-data');
    return new Promise((resolve, reject) => {
      this._api.post(api.product.createByExecel, formData, { headers: headers }).subscribe((res: any) => {
        console.log('response==', res);
        if (res && res.status != 'error') {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  public viewProductDetail(slug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.product.details.replace(':slug', slug)).subscribe((res: any) => {
        if (res) {
          console.log('Product Details', res);
          resolve(res);
        } else {
          reject(res);
        }
      })
    })
  }

  submitProductUpdateForm(formData: any, id): Promise<any> {
    console.log("Formdata : ", formData);

    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'multipart/form-data');
      headers = headers.append('enctype', 'multipart/form-data');

      this._api.post(api.product.UpdateById.replace(':id', id), formData, { headers: headers }).subscribe((res: any) => {
        console.log('submitProductCreateForm RES ', res);
        if (res && res.status == "error") {
          reject(res)
        } else {
          resolve(res)
        }
      })
    });
  }

  public getTransactionHistroy(timeUnit: any, page: number = 1, limit: number = 20): Promise<any> {
    return new Promise((resolve, reject) => {
      // /api/orders/my-earnings?timeUnit=THIS_MONTH&page=1&limit=20
      this._api.get(api.order.myEarnings + `?timeUnit=${timeUnit}&page=${page}&limit=${limit}`).subscribe((res: any) => {
        if (res && res.data) {
          // console.log('data >>', res )
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  submitProductImagesForm(formData: any, id): Promise<any> {
    console.log("Formdata : ", formData);

    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'multipart/form-data');
      headers = headers.append('enctype', 'multipart/form-data');

      this._api.post(api.product.upload_images.replace(':id', id), formData, { headers: headers }).subscribe((res: any) => {
        console.log('submitProductImagesForm RES ', res);
        if (res && res.status == "error") {
          reject(res)
        } else {
          resolve(res)
        }
      })
    });
  }

  getCategoryDetailsById(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get((api.product.category.replace(':id', id))).subscribe((res: any) => {
        if (res) {
          console.log('Category Details', res);
          resolve(res);
        } else {
          reject(res);
        }
      })
    })
  }

  getTransactions(fromDate, toDate): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.dealer.transactions.replace('from=fromDate&to=toDate', 'from=' + fromDate + '&to=' + toDate)).subscribe((res: any) => {
        if (res) {
          console.log('Category Details', res);
          resolve(res);
        } else {
          reject(res);
        }
      })
    })
  }

  async downloadReceipt(orderId) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    const url = `api/orders/download/${orderId}`;
    await this._api.get(api.order.download.replace(':order_id', orderId), { headers: headers, responseType: 'arraybuffer' }).toPromise().then((result: any) => {
      return result;
    });
  }

  getSavedCardDetails(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.order.stripeCards).subscribe((res: any) => {
        if (res) {
          console.log('Category Details', res);
          resolve(res);
        } else {
          reject(res);
        }
      })
    })
  }

  createCard(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.post(api.order.createCard, data).subscribe((res: any) => {
        resolve(res);
      });
    });
  }

  public getFavorites(page: number = 1, limit: number = 20, sortBy: string = 'created_at', sortOrder: string = 'DESC', search: string = '', filters: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let filterString = ''
      Object.keys(filters).filter((key: any) => {
        if (filters[key] && filters[key].length > 0) {
          filterString += `&filters[${key}]=${filters[key].toString()}`
        }
      })
      this._api.get(api.product.myFavorite + `?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}&category_id=2${filterString}`).subscribe((res: any) => {
        if (res) {
          resolve(res);
        }
      });
    });
  }

  public addToFavourite(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.post(api.product.addToFavorite, data).subscribe((res: any) => {
        console.log("Review request api res : ", res);
        if (res && res.statusCode == 200 && res.status == 'success') {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

  public viewOrderTrackingDetail(id: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.order.trackingInfo.replace(':id', id)).subscribe((res: any) => {
        if (res) {
          console.log('Product Details', res);
          resolve(res);
        } else {
          reject(res);
        }
      })
    })
  }

  public updateProductStatus(id: any, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.post(api.product.updateStatus.replace(':id', id), data).subscribe((res: any) => {
        console.log("Review request api res : ", res);
        if (res) {
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }


  public DeleteYourAcount(): Promise <any>{
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('enctype', 'multipart/form-data');
    return new Promise((resolve, reject) => {
      this._api.post(api.auth.deleteAccount , { headers: headers}).subscribe((res:any) => {
        if(res) {
          resolve(res);
        } else {
          reject(res);
        }
      })
    })
  }

  getAttributeList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.product.attributeList).subscribe((res: any) => {
        if (res) {
          console.log('Attribute Details', res);
          resolve(res);
        } else {
          reject(res);
        }
      })
    })
  }
}
