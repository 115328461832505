import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService } from '@core/services/alert.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { UtilityService } from '@core/services/utility.service';
import { CustomOverlayConfig, TemplateModalService } from '@features/customCDK/cdkCustomMdl/services/template-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from '@views/common/dashboard/dashboard.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit, OnDestroy, AfterViewInit {

  paymentInformationForm: FormGroup;
  email: string;
  loggedInUserData: any;
  @Output() addressDetailsSubmit = new EventEmitter();
  customConfig!: CustomOverlayConfig;
  @Input() cardDetails = null;
  addressModal: any;
  countryList: any;
  stateList: any;
  selectedCountry: any;
  isEditAddress: boolean = false;

  /*-------------- STRIPE ELEMENTS  ------------- */
  savedCards: any = [];
  card: any;
  cardHandler = this.onChange.bind(this);
  selectedCard = 'card_new';
  error: string;
  stripe: any;
  elements: any;

  /*-------------- PAYMENT FORM  ------------- */
  paymentId: string;
  consent: boolean = false;
  showConsentError: boolean = false;
  public paymentSuccess: boolean;
  submitted = false;
  submitLoading = false;
  token: any;

  @ViewChild('cardInfo') cardInfo: ElementRef;
  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    console.log("Processing beforeunload...");
    event.returnValue = false;
  }

  constructor(
    private _addCard: DashboardService,
    private _alert: AlertService,
    private _utility: UtilityService,
    private _storageService: LocalStorageService,
    private _cdr: ChangeDetectorRef,
    private translate:TranslateService
  ) {
    this.loggedInUserData = this._storageService.getData('userDetails');

    this.email = this.loggedInUserData.email;
    this.stripe = Stripe(environment.stripeKey);
    this.elements = this.stripe.elements();
  }
  
  get paymentInfoForm() {
    return this.paymentInformationForm.controls;
  }

  ngOnInit(): void {
    // this.getUserCards();
  }

  ngAfterViewInit() {
    // 1) setup stripe payment card input box 
    this.card = this.elements.create('card', { hidePostalCode: true });
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    try {
      this.card.removeEventListener('change', this.cardHandler);
      this.card.destroy();
    } catch (e) {
    }
  }

  setSelectedCard(card_id: any) {
    this.selectedCard = card_id;
  }

  onChange({ error }) {
    // this.setSelectedCard('card_new');
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this._cdr.detectChanges();
  }

  async submitPayment(isFormValid: boolean) {
    const { token, error } = await this.stripe.createToken(this.card, { email: this.email });
    if (error) {
      this._alert.error(error.message);
    }
    else {
      this.token = token;
      this._addCard.createCard({ card_token: token?.id })
        .then((res: any) => {
          if (res?.id) {
            this._alert.success(this._utility.getTranslatedMessage('toastrMessages','Cardaddedsuccessfully'))
            // ("Card added successfully")
            this._utility._navigateWithReload('/dashboard/profile');
          }
        })
    }
  }


}
