<div class="headerContainer">
    <div *ngIf="isMobileView" class="installAppContainer">
        <img src="../../../assets/images/golfedWithBg.svg" class="golfedSmallIcon" />
        <div class="appRatingContainer" style="width: 60%;">
            <p class="appText">Golfed App</p>
        </div>
        <div style="display: flex;justify-content: flex-end;width: 40%;">
            <img src="../../../../assets/images/Apple-Store.svg" alt=""
                (click)="goToLink('https://apps.apple.com/in/app/golfed/id6447966849')" style="margin-left: 10%;"
                class="appStoreImg">
            <img src="../../../../assets/images/App-Store.svg"
                (click)="goToLink('https://play.google.com/store/apps/details?id=com.arche.golfed&pli=1')" alt=""
                style="margin-left: 10px; margin-right: 10%;" class="appStoreImg">
        </div>
    </div>

    <div *ngIf="isMsgHidden" class="ratingBar">
        <div class="ratingIcons">
            <img style="height: 30px; width:30px" src="../../../assets/images/startcon.png" />
            <span class="ratingText">
                {{'header.TheBestPlaceToBuyGolf' | translate}}
            </span>
        </div>
        <div class="iconContainer">
            <img src="../../../assets/images/faceBook.svg"
                (click)="goToLink('https://www.facebook.com/people/Golfed/100091649773378/?sk=mentions')"
                style="cursor: pointer" class="faceBookIcon" />
            <img src="../../../assets/images/youtube.svg" class="socialMedialIcon" style="cursor: pointer" />
            <img src="../../../assets/images/instagram.svg"
                (click)="goToLink('https://instagram.com/golfed_europe/?igshid=YmMyMTA2M2Y%3D')"
                class="socialMedialIcon" style="cursor: pointer" />
        </div>
    </div>
    <div class="imageLogInContainer">
        <img data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000" (click)="navigate('home')" src="../../../assets/images/Logo.svg" />
        <div class="sellLoginContainer" #containerOne data-aos="fade-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000">
            <button *ngIf="menuType == 'seller' || menuType == 'user'" routerLink="/pseller/create-product"
                class="sellBtn">{{'header.SellProduct' | translate}}</button>
            <!-- <div style="display: flex;border-radius: 5px;margin: 4px;">
                <mat-form-field appearance="none"   style="width: 60px;margin-bottom: 10px;">
                    <img style="width: 20px; height: 20px; margin-top: 10px;"
                        [src]="translate.currentLang!='en'?'../../../assets/images/countries-flag/'+translate.currentLang+'.png':'../../../assets/images/countries-flag/sh.png'">
                    <mat-select class="dropLang"
                        (selectionChange)="translate.use($event.value);_store.saveSystemSetting('browser-language',langSelect.value)"
                        #langSelect>
                        <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">
                            <img style="width: 20px; height: 20px; margin-top: 10px; align-items: center;"
                                [src]="lang!='en'? '../../../assets/images/countries-flag/'+lang+'.png':'../../../assets/images/countries-flag/sh.png'">
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
            <img src="../../../assets/images/englandFlag.svg" alt="" class="selectOption" />

            <!-- <img (click)="openDrawerMenu()" src="../../../assets/images/userIcon.svg" /> -->
            <mat-icon (click)="openDrawerMenu()" style="cursor: pointer;">person</mat-icon>
            <span *ngIf="alertNotification?.length>0" matTooltip="Notification" matTooltipPosition="below"
                class="badge">{{alertNotification?.length}}</span>
            <p *ngIf="_authService.checkIfLoggedIn()" (click)="openDrawerMenu()" class="loginRegister">
                {{loggedInUser.display_name}}</p>
            <p *ngIf="!_authService.checkIfLoggedIn()" (click)="openDrawerMenu()" class="loginRegister">
                {{'header.LoginOrRegister'|translate}}</p>

            <!-- SIDEBAR ON CLICK OF USER -->
            <!-- DO NOT REMOVE THIS CODE -->

            <div *ngIf="isDrawerOpen" class="sidebarContainer" (document:click)="documentClickedOne($event)">
                <div *ngIf="menuType == 'dealer'"
                    (click)="isDrawerOpen = false; _utilityService._navigate('/dashboard')" class="sidebarContent">
                    <!-- <img src="../../../assets/images/overviewIcon.svg" alt="" srcset="" /> -->
                    <mat-icon>dashboard</mat-icon>
                    <p class="sidebarText">{{'header.Dashboard' | translate}}</p>
                </div>
                <div *ngIf="menuType == 'seller'"
                    (click)="isDrawerOpen = false; _utilityService._navigate('/dashboard/overview')"
                    class="sidebarContent">
                    <!-- <img src="../../../assets/images/overviewIcon.svg" alt="" srcset="" /> -->
                    <mat-icon>dashboard</mat-icon>
                    <p class="sidebarText">{{'header.Overview' | translate}}</p>
                </div>

                <div *ngIf="isMobileView ">
                    <div *ngIf="menuType == 'dealer'">
                        <div style="display: flex; flex-direction: row;">
                            <!-- <img src="../../../assets/images/listingSidebar.svg" alt="" srcset=""
                                style="height: 20px; width: 20px;" /> -->
                                <mat-icon> local_offer</mat-icon>
                            <mat-label style="    width: 30px;
                    height: 40px;
                    padding-top: 0px;
                    margin-left: 18px;
                    font-weight: 400;
                    font-size: 16px;
                    font-family: Roboto;">{{'header.Listing'| translate}}</mat-label>
                            <mat-select>
                                <mat-option [routerLink]="['/dashboard/manage-listing']"
                                    [routerLinkActive]="['is-active']"> <mat-icon>subject</mat-icon>{{'header.ManageAListing'|
                                    translate}}</mat-option>
                                <mat-option [routerLink]="['/dashboard/requests-and-sales']"
                                    [routerLinkActive]="['is-active']"><mat-icon>description</mat-icon>{{'header.RequestsAndSales'|
                                    translate}}</mat-option>
                                <mat-option [routerLink]="['/dashboard/guidelines']"
                                    [routerLinkActive]="['is-active']"><mat-icon>library_books</mat-icon>{{'header.DealerGuidelines'|
                                    translate}}</mat-option>
                            </mat-select>
                        </div>
                        <div style="display: flex; flex-direction: row;">
                            <!-- <img src="../../../assets/images/accountingIcon.svg" alt="" srcset=""
                                style="height: 20px; width: 20px;" /> -->
                                <mat-icon>book</mat-icon>   
                            <mat-label style="    width: 30px;
                    height: 40px;
                    padding-top: 0px;
                    margin-left: 18px;
                    font-weight: 400;
                    font-size: 16px;
                    font-family: Roboto;">{{'header.Accounting'| translate}}</mat-label>
                            <mat-select>
                                <mat-option [routerLink]="['/dashboard/transaction-history']"
                                    [routerLinkActive]="['is-active']"
                                    style="cursor: pointer;"><mat-icon>history</mat-icon>{{'header.TransactionHistory'| translate}}</mat-option>
                                <mat-option [routerLink]="['/dashboard/invoices']" [routerLinkActive]="['is-active']"
                                    style="cursor: pointer;"><mat-icon>assignment_returned</mat-icon>{{'header.Invoices'| translate}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>


                <div (click)="isDrawerOpen = false; _utilityService._navigate('/messages')" class="sidebarContent">
                    <!-- <img src="../../../assets/images/messageIcon.svg" alt="" srcset="" /> -->
                    <mat-icon> message</mat-icon>
                    <p class="sidebarText">{{'header.Messages' | translate}}</p>
                    <span *ngIf="enquiryNotifications?.length>0" class="badge">{{enquiryNotifications?.length}}</span>
                </div>
                <div (click)="isDrawerOpen = false; _utilityService._navigate('/dashboard/buy')" class="sidebarContent">
                    <!-- <img src="../../../assets/images/bag.svg" alt="" srcset="" /> -->
                    <mat-icon>shopping_cart</mat-icon>
                    <p class="sidebarText">{{'header.Buy' | translate}}</p>
                </div>
                <div *ngIf="menuType == 'seller'"
                    (click)="isDrawerOpen = false; _utilityService._navigate('/dashboard/sell')" class="sidebarContent">
                    <!-- <img src="../../../assets/images/sellIcon.svg" alt="" srcset="" /> -->
                    <mat-icon> local_offer</mat-icon>
                    <p class="sidebarText">{{'header.Sell' | translate}}</p>
                </div>
                <hr>
                <div (click)="isDrawerOpen = false; _utilityService._navigate('/dashboard/profile')"
                    class="sidebarContent">
                    <!-- <img src="../../../assets/images/userProfile.svg" alt="" srcset="" /> -->
                    <!-- <mat-icon> person_pin</mat-icon> -->
                    <mat-icon>account_circle</mat-icon>
                    <p class="sidebarText">{{'header.Profile' | translate}}</p>
                </div>
                <div (click)="isDrawerOpen = false; _utilityService._navigate('/dashboard/notification')"
                    class="sidebarContent">
                    <!-- <img src="../../../assets/images/notificationIcon.svg" alt="" srcset="" /> -->
                    <mat-icon>notifications_active</mat-icon>
                    <p class="sidebarText">{{'header.Notifications' | translate}}</p>
                    <span *ngIf="alertNotification?.length>0"
                        class="badge badge-danger ">{{alertNotification?.length}}</span>
                </div>
                <div (click)="isDrawerOpen = false; _utilityService._navigate('/dashboard/change-password')"
                    class="sidebarContent">
                    <!-- <img src="../../../assets/images/change-password.svg" alt="" srcset="" /> -->
                    <mat-icon> vpn_key</mat-icon>
                    <p class="sidebarText">{{'header.ChangePassword' | translate}}</p>
                </div>
                <hr>
                <div (click)="userLogout()" class="sidebarContent">
                    <!-- <img src="../../../assets/images/logoutNew.svg" alt="" srcset="" /> -->
                    <mat-icon>exit_to_app</mat-icon>
                    <p class="sidebarText">{{'header.Logout' | translate}}</p>
                </div>
            </div>

        </div>
    </div>
    <div class="navbarContainer" *ngIf="!isMobileView" style="padding-left: 40px;">
        <nav class="navbar">
            <div class="navbarDetails" *ngIf="isMenuHidden" #containerTwo>
                <p (click)="onClickBuyBtn()" class="selectOptionNavbar" [ngClass]="{ active: buyMenuDrawer }">
                    {{'header.BuyOnGolfed' | translate}}
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </p>
                <p (click)="onClickSellBtn()" class="selectOptionNavbar" [ngClass]="{ active: sellMenuDrawer }">
                    {{'header.SellOnGolfed' | translate}}
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </p>
                <p (click)="_utilityService._navigate('/faq')" class="navbarContent">{{'header.Faq' | translate}}</p>
                <p (click)="_utilityService._navigate('/about-us')" class="navbarContent">{{'header.AboutUs' |
                    translate}}</p>
                <p class="navbarContent" (click)="_utilityService._navigate('/blog')">{{'header.Blogs' | translate}}</p>
            </div>
        </nav>
    </div>
    <div class="navbarContainer" *ngIf="isMobileView" style="padding-left: 20px;">
        <nav class="navbar">
            <div class="navbarDetails" *ngIf="isMenuHidden" #containerTwo>
                
                <p (click)="onClickBuyBtn()" class="selectOptionNavbar" [ngClass]="{ active: buyMenuDrawer }">
                    {{'header.BuyOnGolfed' | translate}}
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </p>
                <p (click)="onClickSellBtn()" class="selectOptionNavbar" [ngClass]="{ active: sellMenuDrawer }">
                    {{'header.SellOnGolfed' | translate}}
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </p>
                <p (click)="_utilityService._navigate('/faq')" class="navbarContent">{{'header.Faq' | translate}}</p>
                <p (click)="_utilityService._navigate('/about-us')" class="navbarContent">{{'header.AboutUs' |
                    translate}}</p>
                <p class="navbarContent" (click)="_utilityService._navigate('/blog')">{{'header.Blogs' | translate}}</p>
            </div>
        </nav>
    </div>
</div>

<!-- BUY ON GOLFED -->
<div *ngIf="buyMenuDrawer" (mouseleave)="buyMenuDrawer = !buyMenuDrawer" class="buyGolfedContainer" style="z-index:1050"
    (document:click)="documentClickedTwo($event)">
    <div class="brands">
        <p class="brandTitle">{{'header.Brands' | translate}}</p>
        <div class="brandNameContainer">
            <div *ngFor="let item of brandList" class="container">
                <li (click)="navigate('brand',item?.id || item?.brand_id)" class="brand">
                    <!-- {{translate.currentLang=='es'? item.name_es :translate.currentLang=='fr'?
                    item.name_fr:translate.currentLang=='nl'? item.name_nl:translate.currentLang=='pt'?
                    item.name_pt:item.name}} -->
                    {{item.name}}
            </div>
        </div>
        <a *ngIf="brandList.length > 9" (click)="_utilityService._navigate('/all-brand-and-categories')"
            class="showMoreBrands">{{'home.ShowMore' | translate}}...</a>
    </div>
    <div class="categories">
        <p class="categoriesTitle">{{'header.Categories' | translate}}</p>
        <div class="categoryNameContainer">
            <div *ngFor="let item of categoryList" class="container">
                <li (click)="navigate('category', item)" class="category">
                    <!-- {{translate.currentLang=='es'? item.name_es :translate.currentLang=='fr'?
                    item.name_fr:translate.currentLang=='nl'? item.name_nl:translate.currentLang=='pt'?
                    item.name_pt:item.name}} -->
                    {{item.name}}
                </li>
            </div>
        </div>
        <a *ngIf="categoryList.length > 10" (click)="_utilityService._navigate('/all-brand-and-categories')"
            class="showMoreCategory" style="text-decoration: underline;
        color: green;
        cursor: pointer;">{{'home.ShowMore' | translate}}...</a>
    </div>
</div>

<!-- SELL ON GOLFED -->
<div *ngIf="sellMenuDrawer" (mouseleave)="sellMenuDrawer = !sellMenuDrawer" class="sellGolfedContainer"
    (document:click)="documentClickedTwo($event)">
    <div class="pvtSeller">
        <mat-icon style="margin-left: 70px;">person</mat-icon>
        <p class="pvtSellerText">{{'header.ImPrivatePerson' | translate}}</p>
        <p class="numberOfSellers">
            {{'header.SellOnYourPName' | translate}}
        </p>
        <p (click)="navigate('pseller')" class="sellProduct"> {{'header.SellProduct' | translate}}
        </p>
    </div>
    <div class="professionalDealer">
        <!-- <mat-icon>people</mat-icon> -->
        <mat-icon style="margin-left: 120px;">group</mat-icon>
        <!-- <mat-icon>domain</mat-icon> -->
        <p class="professionalText">{{'header.ImProOrganisation' | translate}}</p>
        <p class="numberOfSellers">
            {{'header.SellOnCompanyName'|translate}}
        </p>
        <p (click)="navigate('dealer')" class="sellProduct">{{'header.SellProduct' | translate}}</p>
    </div>
</div>