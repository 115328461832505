import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditAddressRoutingModule } from './edit-address-routing.module';
import { EditAddressComponent } from './edit-address.component';
import { PortalModule } from '@angular/cdk/portal';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared.module';
import { ShowErrorsModule } from '../show-errors/show-errors.module';
import { CoreDirectivesModule } from '@core/directives/core-directives.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [EditAddressComponent],
  imports: [
    CommonModule,
    EditAddressRoutingModule,
    PortalModule,
    ReactiveFormsModule,
    ShowErrorsModule,
    CoreDirectivesModule,
    TranslateModule
  ],
  exports: [EditAddressComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class EditAddressModule { }
