<div class="mainContainer">
    <img (click)="_utility._navigate('/')" src="../../../../assets/images/Logo.svg" class="logoImage" />

    <div class="loginFooter">
        <p class="footerText" (click)="_utility._navigate('/privacy-policy')">Data Privacy Policy</p>
        <p class="footerText" (click)="_utility._navigate('/legal-details')">Terms & Conditions</p>
        <p class="footerText">Legal details</p>
        <p class="footerText">Contact</p>
    </div>
</div>
