import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ImageTransform, ImageCroppedEvent, Dimensions } from '../interfaces';
import { base64ToFile } from '../utils/blob.utils';

@Component({
  selector: 'app-image-cropper-main',
  templateUrl: './image-cropper-main.component.html',
  styleUrls: ['./image-cropper-main.component.scss'],
})
export class ImageCropperMainComponent implements OnInit {
  @Input('selectedEvent') selectedEvent: BehaviorSubject<any> =
    new BehaviorSubject(null);
  @Output('onCrop') onCrop = new EventEmitter<any | null>();
  @Output('onCancel') onCancel = new EventEmitter<any | null>();
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = true;
  transform: ImageTransform = {};
  finalCrop: any;
  mainEvent: any;
  constructor() {
    // this.imageChangedEvent = this.selectedEvent;
  }
  ngOnInit(): void {
    this.mainEvent = this.selectedEvent.getValue();
    this.fileChangeEvent(this.mainEvent.event);
  }
  fileChangeEvent(event: any): void {
    console.log(event);
    this.imageChangedEvent = event;
  }

  Crop() {
    this.onCrop.emit(this.finalCrop);
  }

  cancelCrop() {
    this.onCancel.emit(this.mainEvent);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64 as string));

    this.finalCrop = {
      mainEvent: this.mainEvent,
      event: event,
      blob: base64ToFile(event.base64 as string),
    };
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed() {
    console.log('Load failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
    console.log(this.transform);
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }
}
