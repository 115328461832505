import { Injector } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { regExpPattern } from '@core/validators/regExpPatternList';
import { TranslateService } from '@ngx-translate/core';

export function maxAge(max: Number, translateService: any): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const today = new Date();
    const birthDate = new Date(control.value);
    const getYear: any = birthDate.getFullYear();
    const yearsDifference = today.getFullYear() - birthDate.getFullYear();
    const ValidateYear = /^(19|20)\d{2}$/;
    var defaultValidDateEnterMsg = "";
    var defaultAgeGreaterThanMsg = "";

    // let injector = Injector.create([{ provide: TranslateService, useClass: TranslateService, deps: [] }])
    // let service = injector.get(TranslateService);
    translateService.get('showErrorMsgLabel').subscribe((data: any) => {
      defaultValidDateEnterMsg = data.EnterValidDate;
      defaultAgeGreaterThanMsg = data.AgeShouldBeGreaterThan;
    });
    if (!ValidateYear.test(getYear)) {
      return { maxAge: () => defaultValidDateEnterMsg };
    }
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      yearsDifference - 1;
    }

    const isValid = yearsDifference >= max;
    if (isValid) return null;
    else return { maxAge: () => `${defaultAgeGreaterThanMsg} ${max}.` };
  };
}

export function dateValidation(mindate?: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    console.log('control >>', control);

    const today = new Date();
    const dateInserted = new Date(control.value);
    const getYear: any = dateInserted.getFullYear();
    const ValidateYear = /^(19|20)\d{2}$/;
    if (!ValidateYear.test(getYear)) {
      return { validDate: () => `Enter Valid Year.` };
    }
    if (dateInserted.getTime() > today.getTime()) {
      return { validDate: () => `Future date not excepted` };
    }
    if (mindate) {
      today.setDate(today.getDate() - mindate);
      if (dateInserted.getTime() < new Date(today.toISOString().split('T')[0]).getTime()) {
        return { validDate: () => `Min date is not valid` };
      }
    }
    return null;
  };
}

export function comparePassword(
  controlName: string,
  matchingControlName: string
) {
  return (formGroup: FormGroup) => {
    const control: any = formGroup.controls[controlName];
    const matchingControl: any = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ error: () => `Enter valid password` });
    } else {
      matchingControl.setErrors(null);
    }
    return null;
  };
}

export function comparePassword1(
  controlName: string,
  matchingControlName: string,
  error: ValidationErrors
): ValidatorFn {
  return (formGroup: FormGroup): { [key: string]: any } | null => {

    const control: any = formGroup.controls[controlName];
    const matchingControl: any = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return error;
    }

    // set error on matchingControl if validation fails
    var valid = false;

    if (control.value !== matchingControl.value) {
      valid = false;
      matchingControl.setErrors(error);
    } else {
      matchingControl.setErrors(null);
      valid = true;
    }
    return valid ? null : error;
  };
}

export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, '').length) {
    control.setValue('');
  }
  return null;
}

export function RemoveSpecialCharacters(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const nameRegexp: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (control.value && nameRegexp.test(control.value)) {
      return { specialcharacters: true };
    } else {
      return null;
    }
  };
}

export function serviceProvider(): ValidatorFn {
  return (control: AbstractControl): { invalid: boolean } | null => {
    const providerId = control.value;
    if (providerId && providerId == '001') {
      return { invalid: true };
    } else {
      return null;
    }
  };
}

export function regExpPatternValidator(
  regex: RegExp,
  error: ValidationErrors
): ValidatorFn {
  //HOW TO USE
  //[Validators.required, PasswordReg.patternValidator( /\d{2}[A-Za-z]{5}\d{4}[A-Za-z]{1}[A-Za-z\d]{1}[Zz]{1}[A-Za-z\d]{1}/, { wrongPattern: true } )]
  //GSTIN /\d{2}[A-Za-z]{5}\d{4}[A-Za-z]{1}[A-Za-z\d]{1}[Zz]{1}[A-Za-z\d]{1}/
  //PAN   /[A-Z]{5}[0-9]{4}[A-Z]{1}$/
  //Udyogno   /[A-Za-z]{2}\d{2}[A-Za-z]{1}\d{7}/

  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value) {
      // if control is empty return no error
      return null;
    }

    // test the value of the control against the regexp supplied
    const valid = regex.test(control.value);

    // if true, return no error (no error), else return error passed in the second parameter
    return valid ? null : error;
  };
}

/* IN-USE */
export function regExpNativePatternValidator(
  logic: string | RegExp | Function,
  error: ValidationErrors,
  reverseValidation: boolean = false
): ValidatorFn {
  //HOW TO USE
  //[Validators.required, PasswordReg.patternValidator( /\d{2}[A-Za-z]{5}\d{4}[A-Za-z]{1}[A-Za-z\d]{1}[Zz]{1}[A-Za-z\d]{1}/, { wrongPattern: true } )]
  //GSTIN: "/\d{2}[A-Za-z]{5}\d{4}[A-Za-z]{1}[A-Za-z\d]{1}[Zz]{1}[A-Za-z\d]{1}/"
  //PAN: "/[A-Z]{5}[0-9]{4}[A-Z]{1}$/"
  //Udyogno: "/[A-Za-z]{2}\d{2}[A-Za-z]{1}\d{7}/"

  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value === null || control.value === undefined) {
      // if control is empty return no error
      return null;
    }

    let valid!: boolean;
    // console.log(typeof logic, logic);

    if (typeof logic === 'function') {
      logic({
        value: control.value,
        callBack: (val: any, cbError?: ValidationErrors) => {
          valid = val;
          error = cbError ? cbError : error;
        },
      });
    } else if (typeof logic === 'string' || typeof logic === 'object') {
      valid = new RegExp(
        typeof logic === 'string' ? regExpPattern[logic.trim()] : logic
      ).test(control.value);
    }
    // console.log(regExpPattern[logic]);

    // test the value of the control against the regexp supplied

    // const valid = logic.test(control.value);

    // if true, return no error (no error), else return error passed in the second parameter
    if (reverseValidation) {
      valid = !valid;
    }
    // console.log(valid);

    return valid ? null : error;
  };
}

export function isValidNumber(prms: any = {}): ValidatorFn {
  return (control: FormControl): { [key: string]: any } => {
    let val: number = control.value;
    if (isNaN(val) || /\D/.test(val.toString())) {

      return { "number": true };
    } else if (!isNaN(prms.min) && !isNaN(prms.max)) {

      return val < prms.min || val > prms.max ? { "number": true } : null;
    } else if (!isNaN(prms.min)) {

      return val < prms.min ? { "number": true } : null;
    } else if (!isNaN(prms.max)) {

      return val > prms.max ? { "number": true } : null;
    } else {
      return null;
    }
  };
}

// validate the expiry date for card details
export function expiryDateValidation(translateService: TranslateService): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    var expiryDateMsg="";
    translateService.get('showErrorMsgLabel').subscribe((data: any) => {
      expiryDateMsg = data.EnterValidExpiryDate;
    });
    const parseDate = (e) => { return new Date(e.replace('/', '/1/')); };
    const getDiff = (e) => {
      let curr = new Date();
      let dt = parseDate(e);
      let lastDay = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
      let monthDiff = dt.getMonth() - curr.getMonth();
      let yearDiff = 12 * (dt.getFullYear() - curr.getFullYear());
      if (yearDiff == 0 && monthDiff == 0) {
        return curr.getDate() < lastDay.getDate();
      }
      else {
        return yearDiff + monthDiff > 0;
      }
    }
    var result = getDiff(control.value);
    if (!result) {
      return { validDate: () => expiryDateMsg };
    }
    else {
      control.setErrors(null);
    }
    return null;
  };


}