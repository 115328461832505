import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AlertService } from '@core/services/alert.service';
import { AuthAPIService } from '@views/Auth/auth.service';
import { regExpNativePatternValidator } from '@core/validators/validator';
import { UtilityService } from '@core/services/utility.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  newsLetterForm: any = UntypedFormGroup;
  emailErrorMessage: string = "Please enter valid email address";

  constructor(
    private _auth: AuthAPIService,
    private _fb: UntypedFormBuilder,
    private _alert: AlertService,
    public _utilityService: UtilityService,

    private _translate: TranslateService
  ) {

    this._translate.get('showErrorMsgLabel').subscribe((data: any) => {
      this.emailErrorMessage = data.PleaseEnterValidEmail;
    }); this.initializeForm();
  }

  get nlForm() { return this.newsLetterForm.controls; }

  ngOnInit(): void {
  }

  initializeForm() {
    this.newsLetterForm = this._fb.group({
      userEmail: [null, [Validators.required, regExpNativePatternValidator('email', {
        emailError: () => this.emailErrorMessage,
      })]]
    });
  }

  submitNewsLetterForm() {
    if (this.newsLetterForm.invalid) {
      this._utilityService.markFormGroupTouched(this.newsLetterForm)
    } else {
      this._auth.subscribeNewsLetter({ email: this.newsLetterForm.value.userEmail })
        .then((result: any) => {
          this._alert.success((this._utilityService.getTranslatedMessage('toastrMessages','NewsletterSubscribed')))
          // ("Newsletter subscribed!");
          this.newsLetterForm.reset()
        })
        .catch((error) => {
          if (error.message.includes('ER_DUP_ENTRY')) {
            this._alert.error((this._utilityService.getTranslatedMessage('toastrMessages','Youhavealreadysubscribed')), { timer: 3000 })
            // ('You have already subscribed' , { timer: 3000 })
          }
          else if (error.message_code == "INVALID_INPUT") {
            this._alert.error((this._utilityService.getTranslatedMessage('toastrMessages','Pleaseentervalidemailaddress')), { timer: 3000 })
            // ('Please enter valid email address.', { timer: 3000 })
          } else {
            this._alert.error((this._utilityService.getTranslatedMessage('toastrMessages','Unabletosubscribepleasetryagain')), { timer: 3000 })
            // ('Unable to subscribe please try again.', { timer: 3000 })
          }
        })
    }
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }

  openMail(url){
    window.location.href='mailto:info@golfed.be';
  }
}
