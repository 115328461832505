import { OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';
import { ConfirmationModalConfig } from '../services/confirmation-modal.service';

export class ConfirmationModalOverlayRef {
    public events = new BehaviorSubject<any>(null);

    constructor(private overlayRef: OverlayRef) { }

    public close(customConfig?: ConfirmationModalConfig): void {
        if (customConfig?.isAnimation?.outAnimationStyle) {
            this.overlayRef.addPanelClass('animate__' + customConfig.isAnimation.outAnimationStyle)
            setTimeout(() => {
                this.overlayRef.dispose();
            }, 600);
        } else {
            this.overlayRef.dispose();
        }
    }
}
