import { Injectable, Inject, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, delay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AlertService } from './alert.service';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  private base_path: any = environment.baseURL + environment.apiURL;

  constructor(
    private http: HttpClient,
    private _alert: AlertService,
    private _translate: TranslateService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) { }

  // Handle API errors
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(() => {
      return errorMessage;
    });
  }

  // Global Get Data
  get(endpoint: string, headers?: any, isbasePath?: string): Observable<any> {
    let endPoint: any = isbasePath ? endpoint : this.base_path + '' + endpoint;
    endPoint.includes('?') ? endPoint += `&locale=${this._translate.currentLang}` : endPoint += `?locale=${this._translate.currentLang}`;
    return this.http.get(endPoint, headers).pipe(
      catchError((err) => {
        // this._alert.error(`Server is not responding`);
        return of(err);
      }),
      retry(0),
      catchError(this.handleError)
    );
  }

  // Global Post Data
  post(endpoint: string, data: any, headers?: any, isbasePath?: string): Observable<any> {
    let endPoint: any = isbasePath ? endpoint : this.base_path + '' + endpoint;
    endPoint.includes('?') ? endPoint += `&locale=${this._translate.currentLang}` : endPoint += `?locale=${this._translate.currentLang}`;
    return this.http.post<any>(endPoint, data, headers).pipe(
      catchError((err) => {
        // this._alert.error(`Server is not responding`);
        return of(err);
      }),
      retry(0),
      catchError(this.handleError)
    );
  }

  // Global Get Data with custom URL
  /* get(endpoint: string, custom: any = {}): Observable<any> {
    endpoint =
      custom && custom.url ? custom.url : this.base_path + '' + endpoint;
    return this.http.get(endpoint).pipe(retry(0), catchError(this.handleError));
  } */

  postMulti(endpoint: string, loopPayload: any[]): Promise<any> {
    return Promise.all(
      loopPayload.map((data) => {
        return new Promise((resolve) => {          
          fetch(this.base_path + '' + endpoint, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
          }).then((response) => {
            return new Promise(() => {
              response.json().then((result) => {
                let obj = {
                  methodId: data.MethodId,
                  result: result,
                };
                resolve(obj);
              });
            });
          });
        });
      })
    );
  }
}
