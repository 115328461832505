import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { config } from '@core/config'
import { AlertService } from '@core/services/alert.service'
import { AuthService } from '@core/services/auth.service'
import { LoaderService } from '@core/services/loader.service'
import { UtilityService } from '@core/services/utility.service'
import { Observable } from 'rxjs'

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
    unAuthorisedRoute: any[] = ['auth/login', 'auth/register', 'auth/forgot_password']
    // authorisedRoute: any[] = [
    //     'seller/register', 'seller/update_info', 'seller/update_payout_info', 'seller/upload-photoid',
    //     'dealer/update_info', 'dealer/update_payout_info', 'dealer/upload-photoid',
    //     'orders/create-request', 'orders/view',
    //     'products/create',
    // ]

    constructor(
        private router: Router,
        private loader: LoaderService,
        private _auth: AuthService,
        private _alert: AlertService,
        private _utility:UtilityService
    ) { }
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers: any;  /* {'Content-Type': 'application/x-www-form-urlencoded'} */
        const BASIC_HEADER_TOKEN = 'Basic ' + config.basicAuth;

        if (this.unAuthorisedRoute.some(el => request.url.includes(el))) {
            headers = new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: BASIC_HEADER_TOKEN,
            });
        } else {
            console.log(request.headers.has('Content-Type'));
            if (request.headers['headers'].size > 0) {
                headers = new HttpHeaders({
                    Authorization: 'Bearer ' + this._auth.getAuthorizationToken(),
                });
                for (const [key, value] of Object.entries(request.headers['headers'])) {
                    console.log(`${key}: ${value}`);
                    headers.append(key, value);
                }
            } else {
                headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this._auth.getAuthorizationToken(),
                });
            }
        }

        const cloneReq = request.clone({ headers });
        this.loader.show();
        return new Observable(observer => {
            const subscription = next.handle(cloneReq).subscribe(
                {
                    next: (event) => {
                        if (event instanceof HttpResponse) {
                            this.loader.hidden(false);
                            this.loader.hide();
                            if (event.status !== 400) {
                                observer.next(event);
                            } else {
                                this.router.navigateByUrl('/auth/login')
                            }
                        }
                    },
                    error: (err) => {
                        this.loader.hidden(false);
                        this.loader.hide();
                        if (err.status == 401) {
                            this._alert.error(this._utility.getTranslatedMessage('toastrMessages','Yoursessionhasbeenexpired'))
                            // ('Your session has been expired, please login again.')
                            this._auth.clearStorage();
                            this.router.navigateByUrl('/auth/login');
                        }
                        observer.error(err);
                    },
                    complete: () => {
                        this.loader.hidden(false);
                        this.loader.hide();
                        observer.complete();
                    }
                }
            );
            // remove request from queue when cancelled
            return () => {
                subscription.unsubscribe();
            };
        });
    }
}
