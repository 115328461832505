import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { CustomOverlayConfig } from '../services/template-modal.service';
export interface OverlayCloseEvent<R> {
  type: 'ok' | 'close';
  data: R;
}

export class TemplateModalOverlayRef {
  afterClosed$ = new Subject<OverlayCloseEvent<any>>();

  constructor(private overlayRef: OverlayRef) { }

  close(customConfig?: CustomOverlayConfig|null, method?: any): void {
    if (customConfig?.isAnimation?.outAnimationStyle) {
      this.overlayRef.addPanelClass('animate__' + customConfig.isAnimation.outAnimationStyle)
      setTimeout(() => {
        this._close(method ?? 'close', customConfig);
      }, 600);
    } else {
      this._close(method ?? 'close', customConfig);
    }
  }

  private _close(type: 'ok' | 'close', data?: any) {
    this.overlayRef.dispose();
    this.afterClosed$.next({
      type,
      data
    });
    this.afterClosed$.complete();
  }
}
