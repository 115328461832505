<div class="paymentInfoSection">
    <p class="paymentInfoHeading">{{'profile.AddCard'|translate}}</p>
    <div class="paymentInfoFormAndTrial">
        <form class="paymentInfoFormSection" #checkout="ngForm" (submit)="submitPayment(checkout.valid)">
            <div class="paymentSafePlayAndCard">
                <div class="paymentSafePlay">
                    <p class="paymentSafePlayTxt">{{'dealer.poweredBy'|translate}}</p>
                    <img class="paymentSafePlayImg" src="../../../../../assets/images/stripe-pay.png" alt="StripePay" />
                </div>
                <div class="paymentCardSection">
                    <img src="../../../../../assets/images/paymentOne.svg" alt="VisaCardImg" />
                    <img class="paymentCardImg" src="../../../../../assets/images/paymentTwo.svg" alt="MasterCardImg" />
                    <img class="paymentCardImg" src="../../../../../assets/images/paymentThree.svg"
                        alt="AmericanExpressImg" />
                </div>
            </div>

            <p class="enterCardDetail">{{'paymentPage.EnterCardDetails'|translate}}</p>
            <div class="flex-payment-card">
                <div class="credit-card" style="background:#fff">
                    <div id="card-info" #cardInfo></div>
                    <div class="col-red" role="alert" *ngIf="error">{{ error }}</div>
                </div>
            </div>

            <div class="paymentBackAndContinueBtn">
                <button class="paymentContinueBtn" type="submit">{{'profile.AddCard'|translate}}</button>
            </div>
        </form>
    </div>
</div>