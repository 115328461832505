// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  // baseURL: 'http://localhost:3001/',
  // baseURL: 'https://golfed-api.archesoftronic.com/',
  // baseURL:"https://api-qa.golfed-europe.eu/",
  baseURL: 'https://api.golfed-europe.eu/',
  apiURL: 'api/',
  stripeKey: `pk_live_51M9tzXE4Jg2znPuBV91ORKRghy9Qasmtk4OsA1y9yeQDwWSy1rFp5pBXBoYDgygHQIDUnhYjncKKQiUsLiiOyXMi00UYAfYvk6`,
  // stripeKey: 'pk_test_51M9tzXE4Jg2znPuBAdh0ElxhTEwhLsk8NVEuaCzCWzR9qZ0LpaZWGXZiKtlsFb9KK6Iqw913Gr0eGLoTIcp0lYyK00k6fyw9kh',
  firebase: {
    apiKey: "AIzaSyAkrT6_i-9yq99leOvcPY3ApWW4MG-iWXg",
    authDomain: "golfed-web.firebaseapp.com",
    projectId: "golfed-web",
    storageBucket: "golfed-web.appspot.com",
    messagingSenderId: "30647121970",
    appId: "1:30647121970:web:6d0c26b19efbeefd35242b",
    measurementId: "G-SCGKR3C0F0",
    vapidKey: 'BNvAC-aISI_sm-cVdjj7CuXiFJw7Gg4girdVGtuFRZGrj7gddFXAUE4RFA2Trvcre9QJyACWi3Y_iDmaEYbUzc0',
  },
  // firebase: {
  //   apiKey: 'AIzaSyCdUG9cwcnK6woNWH4WRboaLYRf9FZMvzo',
  //   authDomain: 'golfed-b29f6.firebaseapp.com',
  //   projectId: 'golfed-b29f6',
  //   storageBucket: 'golfed-b29f6.appspot.com',
  //   messagingSenderId: '881520925381',
  //   appId: '1:881520925381:web:621899564aded05cabeeac',
  //   measurementId: 'G-GBLM6X4095',
  //   vapidKey:
  //     'BFoaChc9oEfsBSyKNiebSZdALr_vh3zg9dyEc1snImn5waJfNFERsg0Ttmnbh4gPSpKVti8-pWVzZtgJYJGJ1no',
  // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
