<div class="footerContainer">
    <div class="footer">
        <div class="footerContent">
            <div class="footerGolfedContainer">
                <div class="aboutGolfed">
                    <p class="titleText">{{'footer.AboutGolfed'|translate }}</p>
                    <p (click)="_utilityService._navigate('/about-us')" class="detailText">
                        {{'header.AboutUs'|translate}}</p>
                    <p (click)="_utilityService._navigate('/legal-details')" class="detailText">
                        {{'footer.Terms&Conditions'|translate}}</p>
                    <p (click)="_utilityService._navigate('/privacy-policy')" class="detailText">
                        {{'footer.PrivacyPolicy'|translate}}</p>
                </div>

                <div class="sellOnGolfed">
                    <p class="titleText">{{'header.SellOnGolfed'|translate}}</p>
                    <p (click)="_utilityService._navigate('/pseller/create-product')" class="detailText">
                        {{'footer.SellingGolfAsAPrivateSeller'|translate}}</p>
                    <p (click)="_utilityService._navigate('/dealer/info')" class="detailText">
                        {{'footer.SellingGolfCommercially'|translate}}</p>
                </div>
                <div class="buyGolfed">
                    <p class="titleText">{{'header.BuyOnGolfed'|translate }}</p>
                    <p (click)="_utilityService._navigate('/product/list')" class="detailText">
                        {{'footer.AllProducts'|translate}}</p>
                </div>

                <div class="support">
                    <p class="titleText">{{'footer.ContactUs'|translate }}</p>
                    <p class="cellNumber" (click)="openMail('info@golfed.be')">
                        <img src="../../../assets/images/email-inbox.svg" />
                        <span class="numberText"
                            style="font-size: 18px;font-weight: 500; font-family: 'lato';" routerLink="mailto:info@golfed.be">
                            info@golfed.be
                        </span>
                    </p>
                    <p (click)="_utilityService._navigate('/faq')" class="detailText">
                        {{'footer.FrequentlyAskedQuestions'|translate}}
                    </p>
                    <p (click)="_utilityService._navigate('/contact')" class="detailText">
                        {{'footer.ContactUs'|translate}}</p>
                </div>
            </div>
            <div class="appsMethods">
                <!-- THIS golfedApps CONTAINER IS FOR MOBILE  -->

                <div class="searchContainer">
                    <p class="newsletter">{{'footer.GolfedNewsletter'|translate}}</p>
                    <div class="inputBtn">
                        <form [formGroup]="newsLetterForm" novalidate>
                            <div style="display: flex; flex-direction: row;">
                                <input formControlName="userEmail" class="emailField" placeholder="Your email address"
                                    [ngClass]="{'is-invalid': nlForm.userEmail.invalid && nlForm.userEmail.touched}" />
                                <button (click)="submitNewsLetterForm()"
                                    class="signUpBtn">{{'footer.Subscribe'|translate}}</button>
                            </div>
                            <app-show-errors [ctrl]="nlForm.userEmail" [fieldLabel]="'Email address'"></app-show-errors>
                        </form>
                    </div>
                </div>

                <div class="golfedApps">
                    <p class="golfedAppsText">{{'footer.InstallTheAppAndTry'|translate}}:</p>
                    <div class="appContainer">
                        <img class="appleStoreImg" src="../../../assets/images/appStoreWeb.svg"
                            (click)="goToLink('https://apps.apple.com/in/app/golfed/id6447966849')"
                            style="cursor: pointer;" />
                        <img class="googlePlayImg" src="../../../assets/images/googlePlayWeb.svg"
                            (click)="goToLink('https://play.google.com/store/apps/details?id=com.arche.golfed&pli=1')"
                            style="cursor: pointer;" />
                    </div>
                </div>
                <div class="paymentMethods">
                    <p class="paymentText">{{'footer.PaymentMethods'|translate}}</p>
                    <div class="methodContainer">
                        <img src="../../../assets/images/paymentOne.svg" />
                        <img src="../../../assets/images/paymentTwo.svg" class="paymentImage" />
                        <img src="../../../assets/images/paymentThree.svg" />
                        <img src="../../../assets/images/banContact.png" style="height: 35px; width: 50px"
                            class="paymentImage">
                    </div>
                    <img src="../../../assets/images/Stripe.svg" alt="">
                    <!-- <img src="../../../assets/images/stripeLogo.jpg" alt=""> -->
                </div>
            </div>
            <hr class="footerDivider" />
        </div>
    </div>
</div>


<!-- FOOTER VERSION 2 -->


<!-- <div class="footerContainerV2">

    <div class="footerOne">

        <button class="footerBtn">
            <span class="countryFlagImg"><img src="./images/image 6.svg"></span>

            <span class="countryLanguage">
                <select class='select'>
                    <option value="0">.IN | English | INR</option>
                </select>
            </span>
            <span class="dropdownImg"><img src="./images/Vector 9.svg"></span>
        </button>

        <div class="contectNumberInfo">
            <span class="phoneImg"><img src="./images/Vector 189.svg"></span>
            <span class="contectNumber">+123 4567 7891</span>
        </div>
    </div>

    <div class="footerLinks">

        <div class="footerLinksOne">
            <span class="footerLink">Data Privacy Policy</span>
            <span class="footerLink">Terms & Conditions</span>
            <span class="footerLink">Legal details</span>
        </div>

        <div class="footerLinksTwo">
            <span class="footerLink">Contact</span>
            <span class="footerLink"> Cobrowsing</span>
        </div>

    </div>

</div> -->