import { HttpErrorResponse } from '@angular/common/http';
import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { api } from '@core/config';
import { BaseService } from '@core/services/base.service';
import { CacheDataService } from '@core/services/cache-data.service';
import { LoaderService } from '@core/services/loader.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { NotificationService } from '@views/common/notification/notification.service';
import { Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessagingService } from './service/messaging.service';
import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgcCookieConsentService, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthAPIService } from '@views/Auth/auth.service';
import { AuthService } from '@core/services/auth.service';
import { Title } from '@angular/platform-browser';
declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'golfed-customer-portal';
  isLoading: boolean = false;
  message: any;
  userInfo: any;
  currentUrl: any = '/';
  loadingDataImg: boolean = false;
  currentLanguage: any;

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(
    private loader: LoaderService,
    private _cache: CacheDataService,
    private _api: BaseService,
    private messagingService: MessagingService,
    private _localStorageService: LocalStorageService,
    private _notificationService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private ccService: NgcCookieConsentService,
    public translate: TranslateService,
    private authApiService: AuthAPIService,
    private _authService: AuthService,
  ) {

    this.currentLanguage = this._localStorageService.getSystemSetting('browser-language');
    translate.addLangs(['en', 'fr', 'es', 'nl', 'pt']);
    if (this.currentLanguage) {
      translate.setDefaultLang(this.currentLanguage);
      translate.use(this.currentLanguage);
      translate.currentLang = this.currentLanguage;
    }
    else {
      translate.setDefaultLang('en');
      this._localStorageService.saveSystemSetting('browser-language', 'en')
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|fr|es|nl|pt/) ? browserLang : 'en');
    }

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.initializeSubscription = this.ccService.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      });

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      });

      this.setCookieMassges();


    this._unsubscribeAll = new Subject();

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        console.log("event Current URL : ", this.currentUrl);
        this.navigationInterceptor(event);
      }
    });

    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   gtag('event', 'page_view', {
    //     page_path: event.urlAfterRedirects
    //   })
    // })
    /** END : Code to Track Page View  using gtag.js */

    //Add dynamic title for selected pages - Start
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
        })
        var routeName: any = this.getTitle(router.routerState, router.routerState.root)
        var title =  'Golfed | ' +  routeName[routeName.length - 1];
        console.log("Tiltle 1 ", title)
        titleService.setTitle(title);
      }
    });
    this.loader.isLoading.subscribe((val: boolean) => {
      setTimeout(() => {
        this.isLoading = val;
      }, 1);
    });
    // this.downloadMasterDataAndSystemSettingData();
  }

  ngOnInit(): void {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setCookieMassges();

      this.downloadMasterDataAndSystemSettingData();
      if (this._authService.checkIfLoggedIn()){
      this.authApiService.savePreferredLanguage(this.translate.currentLang)
        .then((res: any) => {
          if (res?.status == 'success') {
            console.log(res?.message);
          }
        })
    }
  });

  if(!isDevMode()) {
  console.log = function () { };
  console.warn = function () { };
}



this.userInfo = this._localStorageService.getData('userDetails');
if (this.userInfo && this.userInfo.id) {
  if (environment.production == true) {
    this.messagingService.requestPermission();
  }
}
this.messagingService.receiveMessage();
this.message = this.messagingService.currentMessage;
  }

ngOnChanges() {
  if (this.userInfo && this.userInfo.id) {
    if (environment.production == true) {
      this.messagingService.requestPermission();
    }
  }

  // unsubscribe to cookieconsent observables to prevent memory leaks

}

setCookieMassges() {
  this.translate.get('cookie').subscribe((data: any) => {
    this.ccService.getConfig().content.message = data.message;
    this.ccService.getConfig().content.dismiss = data.dismiss;
    this.ccService.getConfig().content.allow = data.allow;
    this.ccService.getConfig().content.deny = data.deny;
    this.ccService.getConfig().content.link = data.link;
    this.ccService.getConfig().content.policy = data.policy;

    this.ccService.destroy();//remove previous cookie bar (with default messages)
    this.ccService.init(this.ccService.getConfig()); 
  });
}

ngOnDestroy(): void {
  // Unsubscribe from all subscriptions
  this._unsubscribeAll.next(true);
  this._unsubscribeAll.complete();

  this.popupOpenSubscription.unsubscribe();
  this.popupCloseSubscription.unsubscribe();
  this.initializeSubscription.unsubscribe();
  this.statusChangeSubscription.unsubscribe();
  this.revokeChoiceSubscription.unsubscribe();
  this.noCookieLawSubscription.unsubscribe();
}

downloadMasterData(): Promise < any > {
  return new Promise(async (resolve, reject) => {
    /* CALL ALL API RELATED TO COMMON USE IN APPLICATION */

    let master = await this.getMasterData();
    let all_countries = await this.getCountryList();
    // let categories = await this.getCategoryList();
    // let brands = await this.getBrandList();
    // let user = await this.getUserInfo();

    resolve({ ...master, all_countries });
  });
}

downloadSystemSettings(): Promise < any > {
  return new Promise(async (resolve, reject) => {
    /* CALL ALL API RELATED TO COMMON USE IN APPLICATION */
    let settings = await this.getSystemSettings();
    resolve({ settings });
  });
}

getMasterData(): Promise < any > {
  return new Promise((resolve, reject) => {
    if (this.currentUrl == '/') {
      this.loader.hidden(true)
    }
    this._api.get(api.home.masterData.replace('localeData', this.translate.currentLang)).subscribe((res: any) => {
      if (res instanceof HttpErrorResponse) {
        reject(res.error);
      } else {
        resolve(res);
      }
    });
  });
}

getCountryList(): Promise < any > {
  return new Promise((resolve, reject) => {
    if (this.currentUrl == '/') {
      this.loader.hidden(true)
    }
    this._api.get(api.geo.allCountry).subscribe((res: any) => {
      if (res instanceof HttpErrorResponse) {
        reject(res.error);
      } else {
        resolve(res);
      }
    });
  });
}

getTitle(state, parent) {
  var data = [];
  if (parent && parent.snapshot.data && parent.snapshot.data.title) {
    data.push(parent.snapshot.data.title);
  }
  if (state && parent) {
    data.push(... this.getTitle(state, state.firstChild(parent)));
  }
  return data;
}

// Shows and hides the loading spinner during RouterEvent changes
navigationInterceptor(event: any): void {

  //Triggered When the navigation starts
  if(event instanceof NavigationStart) {
  this.loadingDataImg = true;
}
//Triggered When the navigation ends
if (event instanceof NavigationEnd) {
  this.loadingDataImg = false;
}

// Set loading state to false in both of the below events to hide the spinner in case a request fails
if (event instanceof NavigationCancel) {
  this.loadingDataImg = false;
}
//Triggered When the error occurrs while navigation
if (event instanceof NavigationError) {
  this.loadingDataImg = false;
}
  }

getSystemSettings(): Promise < any > {
  return new Promise((resolve, reject) => {
    this._api.get(api.systemSetting.systemSetting).subscribe((res: any) => {
      if (res instanceof HttpErrorResponse) {
        reject(res.error);
      } else {
        resolve(res);
      }
    });
  });
}

downloadMasterDataAndSystemSettingData() {
  this.downloadMasterData()
    .then((result: any) => {
      let data = {
        // all_countries: result.country || [],
        ...(result || {}),
      };
      this._cache.masterDownloadData = data;
    })
    .catch((err: any) => {
      console.log('Unable to set master data', err);
    });

  this.downloadSystemSettings()
    .then((result: any) => {
      let data = {
        ...(result || {}),
      };
      console.log("System Setting Data : ", data);
      this._cache.systemSettingData = data;
    })
    .catch((err: any) => {
      console.log('Unable to set system settings', err);
    });
}

}
