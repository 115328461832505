import { Component, Input, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss'],
})
export class ShowErrorsComponent implements OnInit {
  @Input() customStyle: any;
  @Input() ctrl: any;
  @Input() setErrorMessage: {} = {};
  @Input() fieldLabel: any = '';
  @Input() requiredMesg: any = "";
  @Input() patternMesg: string = 'Invalid input format';

  defaultRequiredMesage: string = "";
  defaultValidMessage: string = "";
  defaultInput: string = "";
  defaultMin: string = "";
  defaultMax: string = "";
  defaultCharactersIsRequired: string = "";
  defaultIsRequired: string = "";
  defaultCharactersAreAllowed: string = "";
  defaultValidDateEnterMsg: string = "";
  defaultSpecialCharMsg: string = "";
  defaultPasswordMatchMsg: string = "";

  ERROR_MESSAGE: ValidationErrors = {
    required: () => this.fieldLabel != '' ? this.fieldLabel + ' ' + this.defaultRequiredMesage + ' ' : this.requiredMesg,
    email: () => `${this.defaultValidMessage} ${this.fieldLabel.toLowerCase()}`,
    // maxAge: () => `Age should be greather then 18.`,
    pattern: (par: any) => this.patternMesg.replace(this.defaultInput, this.fieldLabel),
    minlength: (par: any) => `${this.defaultMin} ${par.requiredLength} ${this.defaultCharactersIsRequired}`,
    min: (min: any) => `${this.defaultMin} ${this.fieldLabel} ${min.min} ${this.defaultRequiredMesage}.`,
    max: (max: any) => `${this.defaultMax} ${this.fieldLabel} ${max.max} ${this.defaultRequiredMesage}.`,
    maxlength: (par: any) => `${this.defaultMax} ${par.requiredLength} ${this.defaultCharactersAreAllowed}`,
    ptDate: () => this.defaultValidDateEnterMsg,
    specialcharacters: () => this.defaultSpecialCharMsg,
    comparePasswordError: () => this.defaultPasswordMatchMsg
  };

  constructor(private translate: TranslateService) {
    this.setValidationMassges();
  }

  ngOnInit() {
    this.ERROR_MESSAGE = _.assign({}, this.ERROR_MESSAGE, this.setErrorMessage);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setValidationMassges();
    });
  }

  setValidationMassges() {
    this.translate.get('showErrorMsgLabel').subscribe((data: any) => {
      this.defaultValidMessage = data.PleaseEnterValid;
      this.patternMesg = data.InvalidInputFormat;
      this.requiredMesg = data.ThisFieldIsRequired;
      this.defaultRequiredMesage = data.IsRequired;
      this.defaultInput = data.Input;
      this.defaultMin = data.Min;
      this.defaultMax = data.Max;
      this.defaultCharactersIsRequired = data.CharactersIsRequired;
      this.defaultCharactersAreAllowed = data.CharactersAreAllowed;
      this.defaultValidDateEnterMsg = data.EnterValidDate;
      this.defaultSpecialCharMsg = data.SpecialCharactersNotAllowed;
      this.defaultPasswordMatchMsg = data.PasswordShouldBeMatch;
    });
  }

  shouldShowErrors() {
    return this.ctrl && this.ctrl.errors && this.ctrl.touched;
  }

  listOfErrors() {
    return Object.keys(this.ctrl.errors).map((err: any) => {
      return this.ERROR_MESSAGE[err] ? this.ERROR_MESSAGE[err](this.ctrl.getError(err)) : this.ctrl.getError(err);
    });
  }
  getTypeOf(error: any) {
    return typeof error
  }
}
