import { Injectable } from '@angular/core';
import { api } from '@core/config';
import { BaseService } from '@core/services/base.service';
import { LoaderService } from '@core/services/loader.service';
import { EventEmitter, promise } from 'protractor';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public _isSyncNotification = new Subject<boolean>();



  constructor(
    private _api: BaseService,
    private loader: LoaderService
  ) {
  }

  public webNotification(isLoaderHide?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      if (isLoaderHide) {
        this.loader.hidden(true);
      }
      this._api.get(api.notification.notify_user).subscribe((res: any) => {
        if (res) {
          //  console.log('Notification Details', res);
          resolve(res);
        } else {
          reject(res);
        }
      })
    })
  }

  public markAsReadNotification(formData: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this._api
        .post(api.notification.mark_user, formData)
        .subscribe((res: any) => {
          if (res && res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        });
    });
  }

  syncNotification(isSync: boolean) {
    this._isSyncNotification.next(isSync);
  }

  public getNotificationPaginated(page: number = 1, limit: number = 20): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api.get(api.notification.notification_paginated + `?page=${page}&limit=${limit}`).subscribe((res: any) => {
        if (res && res.data) {
          console.log("Paginated notifications : ",res);
          
          resolve(res);
        } else {
          reject(res);
        }
      });
    });
  }

}
