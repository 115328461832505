import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@core/services/alert.service';
import { AuthService } from '@core/services/auth.service';
import { CacheDataService } from '@core/services/cache-data.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ResponsiveService } from '@core/services/responsive.service';
import { UtilityService } from '@core/services/utility.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@views/common/notification/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import AOS from "aos";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],

})
export class HeaderComponent implements OnInit {

  listingDrawer: boolean = false;
  loggedInUser: any = {};
  buyMenuDrawer: boolean = false;
  sellMenuDrawer: boolean = false;
  isDrawerOpen: boolean = false;

  isMenuHidden: boolean = false;
  isMsgHidden: boolean = false;
  isTestButton = false;
  brandList: any = [];
  categoryList: any = [];

  loggedInUserData: any;
  menuType: string = '';
  isMobileView: boolean = true;
  allNotifications: any;
  orderNotifications: any = [];
  productNotifications: any = [];
  enquiryNotifications: any = [];
  alertNotification: any = [];
  isUserLoggedIn: boolean;
  private _unsubscribeAll: Subject<any> = new Subject();
  @ViewChild('containerOne') containerRefOne: ElementRef;
  @ViewChild('containerTwo') containerRefTwo: ElementRef;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private _cache: CacheDataService,
    public _authService: AuthService,
    public _store: LocalStorageService,
    private _eref: ElementRef,
    private _breakPoint: ResponsiveService,
    private _cdr: ChangeDetectorRef,
    public _utilityService: UtilityService,
    private _alert: AlertService,
    private _notific: NotificationService,
    public translate: TranslateService
  ) {
    this.loggedInUserData = this._store.getData('userDetails');
    this.menuType = this.loggedInUserData?.role || '';
    this.isUserLoggedIn = this._authService.checkIfLoggedIn();
    _cache.masterDownloadData
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: any) => {
        if (data) {
          this.brandList = data.all_brands;
          this.categoryList = data.all_categories;
        }
      });

    _breakPoint.BreakpointObserver
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        console.log('screenResult >>', result)
        this.isMobileView = result;
      })

    this.router.events.subscribe((event: any) => {
      this.isMenuHidden = event.url == '/pseller/create-product' || event.url == '/dealer/registration' ? true : true;
      this.isMsgHidden = this.isMenuHidden
    });



  }

  documentClickedTwo({ target }: MouseEvent) {
    if (!this.containerRefTwo.nativeElement.contains(target)) {
      this.buyMenuDrawer = false;
      this.sellMenuDrawer = false;
    }
  }

  documentClickedOne({ target }: MouseEvent) {
    if (!this.containerRefOne.nativeElement.contains(target)) {
      this.isDrawerOpen = false;
    }
  }

  ngOnInit(): void {

    AOS.init();

    if (this.isUserLoggedIn) {
      this.getNotification();
    }
    this.loggedInUser = this._store.getData('userDetails');

    setInterval(() => {
      if (this.isUserLoggedIn) {
        this.getNotification(true);
      }
    }, 100000);

    this._notific._isSyncNotification
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(isSync => {
        if (isSync == true) {
          this.getNotification();
        }
      });
  }

  onClickBuyBtn() {
    this.buyMenuDrawer = !this.buyMenuDrawer;
    this.sellMenuDrawer = false;
  }

  onClickSellBtn() {
    this.sellMenuDrawer = !this.sellMenuDrawer;
    this.buyMenuDrawer = false;
  }

  openDrawerMenu() {
    if (this._authService.checkIfLoggedIn()) {
      this.isDrawerOpen = !this.isDrawerOpen;
    } else {
      this._utilityService._navigate('/auth/login');
    }
  }

  navigate(type: string, filterItem?: any) {
    this.sellMenuDrawer = false;
    this.buyMenuDrawer = false;
    this.isDrawerOpen = false;

    switch (type) {
      case "pseller":
        if (this.loggedInUser && this.loggedInUser?.role == 'dealer') {
          this._alert.warn(this._utilityService.getTranslatedMessage('toastrMessages','Dealersarenotallowed'), { timer: 3000 })
          // ('Dealers are not allowed to sell product using this option.', { timer: 3000 })
        } else {
          this._utilityService._navigate("/pseller/create-product");
        }
        break;
      case "dealer":
        this._utilityService._navigate("/dealer/info");
        break;
      case "home":
        this._utilityService._navigate("/");
        break;
      case "brand":
        this._utilityService._navigate("/product/list", { queryParams: { brand_id: filterItem } });
        break;
      case "category":
        this._utilityService._navigate("/product/list", { queryParams: { category_id: filterItem.id } });
        break;
    }
  }

  userLogout() {
    this.isDrawerOpen = false;
    this._authService.clearStorage();
    this._utilityService._navigateWithReload('/auth/login')
  }

  redirectToBrandList(val) {
    this._utilityService._navigate("/product/list", { queryParams: { brand_id: val } });
  }

  getNotification(isLoaderShow?: boolean) {
    this.orderNotifications = [];
    this.enquiryNotifications = [];
    this.productNotifications = [];
    this.alertNotification = [];
    this.allNotifications = [];
    this._notific.webNotification(isLoaderShow)
      .then((res: any) => {
        if (res) {
          this.allNotifications = res;
          this._cache.notifications = this.allNotifications;
          console.log("All Notifications", this.allNotifications);
          if (this.allNotifications != null && this.allNotifications?.length > 0) {
            this.allNotifications?.filter(notify => {
              if (notify.read_at == null) {
                this.alertNotification.push(notify);
              }
              if (notify.notification_type == 'ORDER' && notify.read_at == null) {
                //buy
                this.orderNotifications.push(notify);
              }
              if (notify.notification_type == 'PRODUCT' && notify.read_at == null) {
                //sell
                this.productNotifications.push(notify);
              }
              if (notify.notification_type == 'ENQUIRY' && notify.read_at == null) {
                //message
                this.enquiryNotifications.push(notify);
              }
            });
          }
        }
      })
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }
}
