import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UtilityService } from '@core/services/utility.service';

@Component({
  selector: 'image-upload',
  template: `
  <label
    class="uploader"
    ondragover="return false;"
    [ngClass]="{show: shouldShow, hide: !shouldShow}"
    [class.loaded]="loaded"
    [style.outlineColor]="dragging ? activeColor : baseColor"
    (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
    (drop)="handleDrop($event)"
  >
    <i
      class="icon icon-upload glyphicon glyphicon-upload"
      [style.color]="
        dragging
          ? imageSrc.length > 0
            ? overlayColor
            : activeColor
          : imageSrc.length > 0
          ? overlayColor
          : baseColor
      "></i>
    <img
      [src]="imageSrc"
      (load)="handleImageLoad()"
      [class.loaded]="imageLoaded"
    />

    <input
      type="file"
      name="file"
      accept="image/*"
      (change)="handleInputChange1($event)"
    />
  </label>
`,
  styleUrls: ['./image-upload.component.scss'],
  inputs: ['activeColor', 'baseColor', 'overlayColor'],

})
export class ImageUploadComponent {

  @Output() handleInputChange = new EventEmitter()

  buttonOpacity = 0.1;
  shouldShow = true;
  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';

  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;
  imageSrc: string = '../../../../assets/images/uploadImg.svg';

  constructor(
   private _utility: UtilityService
  ){

  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange1(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange1(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert(this._utility.getTranslatedMessage('toastrMessages','invalidformat'));
      return;
    }

    this.loaded = false;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
    this.handleInputChange.emit(file);
  }

  _handleReaderLoaded(e) {
    var reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
  }

}
