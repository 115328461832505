<ng-template cdkPortal #modalTemplate="cdkPortal">
    <div class="uploadPictureContainer" *ngIf="chartType == 'clothes'">
        <img src="../../../../assets/images/closeIcon.svg" (click)="closeTemplateModal(cdkPortal)" alt="popup_close"
            class="closeIcon">
        <p class="pictureText"></p>
        <p class="pictureGolfText">Size Chart For Clothes</p>

        <div class="orderContainer">
            <div class="size-chart">
                <table>
                    <tr>
                        <th colspan="3" class="main-header">
                            Men
                        </th>
                        <th colspan="3" class="main-header">
                            Women
                        </th>
                        <th colspan="3" class="main-header">
                            Junior
                        </th>
                    </tr>
                    <tr>
                        <th class="sub-header">EU</th>
                        <th class="sub-header">UK</th>
                        <th class="sub-header">US</th>

                        <th class="sub-header">EU</th>
                        <th class="sub-header">UK</th>
                        <th class="sub-header">US</th>

                        <th class="sub-header">EU</th>
                        <th class="sub-header">UK</th>
                        <th class="sub-header">US</th>
                    </tr>
                    <tr>
                        <td>38</td>
                        <td>5</td>
                        <td>6</td>

                        <td>35</td>
                        <td>2</td>
                        <td>4</td>

                        <td>23</td>
                        <td>6</td>
                        <td>7</td>
                    </tr>
                    <tr>
                        <td>39</td>
                        <td>6</td>
                        <td>7</td>

                        <td>35.5</td>
                        <td>2.5</td>
                        <td>4.5</td>

                        <td>24</td>
                        <td>7</td>
                        <td>8</td>
                    </tr>
                    <tr>
                        <td>40</td>
                        <td>6.5</td>
                        <td>7.5</td>

                        <td>36</td>
                        <td>3</td>
                        <td>5</td>

                        <td>25</td>
                        <td>8</td>
                        <td>8.5</td>
                    </tr>
                    <tr>
                        <td>41</td>
                        <td>7</td>
                        <td>8</td>

                        <td>36.5</td>
                        <td>3.5</td>
                        <td>5.5</td>

                        <td>26</td>
                        <td>8.5</td>
                        <td>9</td>
                    </tr>
                    <tr>
                        <td>42</td>
                        <td>7.5</td>
                        <td>8.5</td>

                        <td>37</td>
                        <td>4</td>
                        <td>6</td>

                        <td>27</td>
                        <td>9</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>42.5</td>
                        <td>8</td>
                        <td>9</td>

                        <td>37.5</td>
                        <td>4.5</td>
                        <td>6.5</td>

                        <td>28</td>
                        <td>10</td>
                        <td>11</td>
                    </tr>
                    <tr>
                        <td>43</td>
                        <td>8.5</td>
                        <td>9.5</td>

                        <td>38</td>
                        <td>5</td>
                        <td>7</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>43.5</td>
                        <td>9</td>
                        <td>10</td>

                        <td>38.5</td>
                        <td>5.5</td>
                        <td>7.5</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>44</td>
                        <td>9.5</td>
                        <td>10.5</td>

                        <td>39</td>
                        <td>6</td>
                        <td>8</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>44.5</td>
                        <td>10</td>
                        <td>11</td>

                        <td>39.5</td>
                        <td>6.5</td>
                        <td>8.5</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>45</td>
                        <td>10.5</td>
                        <td>11.5</td>

                        <td>40</td>
                        <td>7</td>
                        <td>9</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>45.5</td>
                        <td>11</td>
                        <td>12</td>

                        <td>40.5</td>
                        <td>7.5</td>
                        <td>9.5</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>46</td>
                        <td>12</td>
                        <td>13</td>

                        <td>41</td>
                        <td>8</td>
                        <td>10</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>47</td>
                        <td>13</td>
                        <td>14</td>

                        <td>41.5</td>
                        <td>8.5</td>
                        <td>10.5</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>48</td>
                        <td>14</td>
                        <td>15</td>

                        <td>42</td>
                        <td>9</td>
                        <td>11</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                    <tr>
                        <td>49</td>
                        <td>15</td>
                        <td>16</td>

                        <td>43</td>
                        <td>10</td>
                        <td>11.5-12</td>

                        <td>38</td>
                        <td>5</td>
                        <td>6</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="uploadPictureContainer" *ngIf="chartType == 'shoes'">
        <img src="../../../../assets/images/closeIcon.svg" (click)="closeTemplateModal(cdkPortal)" alt="popup_close"
            class="closeIcon">
        <p class="pictureText"></p>
        <p class="pictureGolfText">Size Chart For Shoes</p>

        <div class="orderContainer">
            <div class="size-chart">
                <table>
                    <tr>
                        <th colspan="3" class="main-header">
                            Men
                        </th>
                        <th colspan="3" class="main-header">
                            Women
                        </th>
                        <th colspan="3" class="main-header">
                            Junior
                        </th>
                    </tr>
                    <tr>
                        <th class="sub-header">EU</th>
                        <th class="sub-header">UK</th>
                        <th class="sub-header">US</th>

                        <th class="sub-header">EU</th>
                        <th class="sub-header">UK</th>
                        <th class="sub-header">US</th>

                        <th class="sub-header">EU</th>
                        <th class="sub-header">UK</th>
                        <th class="sub-header">US</th>
                    </tr>
                    <tr>
                        <td>36</td>
                        <td>39</td>
                        <td>5.5</td>

                        <td>36</td>
                        <td>35</td>
                        <td>02</td>

                        <td>10.5</td>
                        <td>28</td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td>36 2/3</td>
                        <td>39</td>
                        <td>06</td>

                        <td>36 2/3</td>
                        <td>35</td>
                        <td>2.5</td>

                        <td>11</td>
                        <td>28.5</td>
                        <td>10.5</td>
                    </tr>
                    <tr>
                        <td>37 1/3</td>
                        <td>40</td>
                        <td>6.5</td>

                        <td>37 1/3</td>
                        <td>35-36</td>
                        <td>03</td>

                        <td>11</td>
                        <td>29</td>
                        <td>11</td>
                    </tr>
                    <tr>
                        <td>38</td>
                        <td>40-41</td>
                        <td>07</td>

                        <td>38</td>
                        <td>36</td>
                        <td>3.5</td>

                        <td>12</td>
                        <td>30</td>
                        <td>11.5</td>
                    </tr>
                    <tr>
                        <td>38 2/3</td>
                        <td>41</td>
                        <td>7.5</td>

                        <td>38 2/3</td>
                        <td>36-37</td>
                        <td>04</td>

                        <td>12.5</td>
                        <td>30.5</td>
                        <td>12</td>
                    </tr>
                    <tr>
                        <td>39 1/3</td>
                        <td>41-42</td>
                        <td>08</td>

                        <td>39 1/3</td>
                        <td>37</td>
                        <td>4.5</td>

                        <td>13</td>
                        <td>31</td>
                        <td>12.5</td>
                    </tr>
                    <tr>
                        <td>40</td>
                        <td>42</td>
                        <td>8.5</td>

                        <td>40</td>
                        <td>37-38</td>
                        <td>05</td>

                        <td>13.5</td>
                        <td>31.5</td>
                        <td>13</td>
                    </tr>
                    <tr>
                        <td>40 2/3</td>
                        <td>42-43</td>
                        <td>09</td>

                        <td>40 2/3</td>
                        <td>38</td>
                        <td>5.5</td>

                        <td>01</td>
                        <td>32</td>
                        <td>13.5</td>
                    </tr>
                    <tr>
                        <td>41 1/3</td>
                        <td>43</td>
                        <td>9.5</td>

                        <td>41 1/3</td>
                        <td>38-39</td>
                        <td>06</td>

                        <td>1.5</td>
                        <td>33</td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td>42</td>
                        <td>43-44</td>
                        <td>10</td>

                        <td>42</td>
                        <td>39</td>
                        <td>6.5</td>

                        <td>02</td>
                        <td>33.5</td>
                        <td>1.5</td>
                    </tr>
                    <tr>
                        <td>42 2/3</td>
                        <td>44</td>
                        <td>10.5</td>

                        <td>42 2/3</td>
                        <td>39-40</td>
                        <td>07</td>

                        <td>2.5</td>
                        <td>34</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>43 1/3</td>
                        <td>44-45</td>
                        <td>11</td>

                        <td>43 1/3</td>
                        <td>40</td>
                        <td>7.5</td>

                        <td>03</td>
                        <td>35</td>
                        <td>2.5</td>
                    </tr>
                    <tr>
                        <td>44</td>
                        <td>45</td>
                        <td>11.5</td>

                        <td>44</td>
                        <td>40-41</td>
                        <td>08</td>

                        <td>3.5</td>
                        <td>35.5</td>
                        <td>3</td>
                    </tr>
                    <tr>
                        <td>44 2/3</td>
                        <td>46</td>
                        <td>12.5</td>

                        <td>44 2/3</td>
                        <td>41</td>
                        <td>8.5</td>

                        <td>04</td>
                        <td>36</td>
                        <td>3.5</td>
                    </tr>
                    <tr>
                        <td>45 1/3</td>
                        <td>47</td>
                        <td>13.5</td>

                        <td>45 1/3</td>
                        <td>41-42</td>
                        <td>09</td>

                        <td>4.5</td>
                        <td>36.7</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>46 2/3</td>
                        <td>48</td>
                        <td>14.5</td>

                        <td>46 2/3</td>
                        <td>42</td>
                        <td>9.5</td>

                        <td>5</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>47 1/3</td>
                        <td>49</td>
                        <td>15.5</td>

                        <td>47 1/3</td>
                        <td>42-43</td>
                        <td>10</td>

                        <td>5.5</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>48</td>
                        <td>49</td>
                        <td>15.5</td>

                        <td>48</td>
                        <td>42-43</td>
                        <td>10</td>

                        <td>5.5</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>48 2/3</td>
                        <td>49</td>
                        <td>15.5</td>

                        <td>48 2/3</td>
                        <td>42-43</td>
                        <td>10</td>

                        <td>5.5</td>
                        <td></td>
                        <td></td>
                    </tr>

                </table>
            </div>
        </div>
    </div>
</ng-template>