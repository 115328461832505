<ng-template cdkPortal #modalTemplate="cdkPortal">
  <div class="uploadPictureContainer">
    <img
      src="../../../../assets/images/closeIcon.svg"
      (click)="closeTemplateModal(cdkPortal)"
      alt="popup_close"
      class="closeIcon"
    />
    <p class="pictureText"></p>
    <p *ngIf="isEditAddress" class="pictureGolfText">{{'addressUpdate.EditAddress'|translate}}</p>
    <p *ngIf="!isEditAddress" class="pictureGolfText">{{'addressUpdate.AddAddress'|translate}}</p>
    <div class="orderContainer">
      <div class="orderAddressInfo">
        <form [formGroup]="addressInfoForm" novalidate>
          <div class="mainAddressForm">
            <div class="BillingAddress">
              <div class="billingForm">
                <div class="inputMain">
                  <label class="labelForm">{{'formlabel.Street'|translate}}*</label>
                  <input
                    class="inputType"
                    type="text"
                    formControlName="address_line_1"
                    isSpaceAllow="true"
                    [isDroppable]="false"
                    appInputRestriction="noSpecialChars"
                    placeholder="{{'placeholder.Enter'|translate}} {{'placeholder.Your'|translate}} {{'formlabel.Address'|translate}}"
                    onPaste="return false"
                    [ngClass]="{
                      'is-invalid':
                        addInfoForm.address_line_1.invalid &&
                        addInfoForm.address_line_1.touched
                    }"
                  />
                </div>
                <div class="error-div">
                  <app-show-errors
                    [ctrl]="addressInfoForm.get('address_line_1')"
                    [fieldLabel]="'Street'"
                  >
                  </app-show-errors>
                </div>
                <div class="inputMain">
                  <label class="labelForm">{{'formlabel.No'|translate}}</label>
                  <input
                    class="inputType"
                    type="text"
                    appInputRestriction="noSpecialChars"
                    isSpaceAllow="true"
                    [isDroppable]="false"
                    appInputRestriction="noSpecialChars"
                    formControlName="address_line_2"
                    placeholder="{{'placeholder.Enter'|translate}} {{'formlabel.Street'|translate}} {{'formlabel.No'|translate}}"
                    onPaste="return false"
                    [ngClass]="{
                      'is-invalid':
                        addInfoForm.address_line_2.invalid &&
                        addInfoForm.address_line_2.touched
                    }"
                  />
                </div>
                <div class="error-div">
                  <app-show-errors
                    [ctrl]="addressInfoForm.get('address_line_2')"
                    [fieldLabel]="'No'"
                  >
                  </app-show-errors>
                </div>
                <div class="inputMain">
                  <label class="labelForm">{{'formlabel.ZipOrPostalCodeAndCity'|translate}}*</label>
                  <div class="input-zip">
                    <input
                      class="zipText"
                      type="text"
                      formControlName="zip_code"
                      placeholder="{{'placeholder.Enter'|translate}} {{'checkoutOne.zipCode'|translate}}"
                      minlength="4"
                      maxlength="8"
                      onPaste="return false"
                      appInputRestriction="noSpecialChars"
                      isSpaceAllow="true"
                      [ngClass]="{
                        'is-invalid':
                          addInfoForm.zip_code.invalid &&
                          addInfoForm.zip_code.touched
                      }"
                    />
                    <div class="error-zip-city">
                      <app-show-errors
                        [ctrl]="addressInfoForm.get('zip_code')"
                        [fieldLabel]="'Zip code'"
                      >
                      </app-show-errors>
                    </div>
                  </div>

                  <div class="inputMainForCity">
                    <input
                      class="inputType"
                      type="text"
                      formControlName="city_name"
                      placeholder="{{'placeholder.Enter'|translate}} {{'formlabel.city'|translate}}"
                      appInputRestriction="alphabets"
                      minlength="3"
                      maxlength="25"
                      isSpaceAllow="true"
                      onPaste="return false"
                      [ngClass]="{
                        'is-invalid':
                          addInfoForm.city_name.invalid &&
                          addInfoForm.city_name.touched
                      }"
                    />
                    <div class="error-zip-city">
                      <app-show-errors
                        [ctrl]="addressInfoForm.get('city_name')"
                        [fieldLabel]="'City name'"
                      >
                      </app-show-errors>
                    </div>
                  </div>
                </div>
                <div class="inputMain">
                  <label class="labelForm">{{'formlabel.Country'|translate}}*</label>
                  <select
                    class="inputType"
                    formControlName="country"
                    (change)="PickCountry($event.target.value)"
                    [ngClass]="{
                      'is-invalid':
                        addInfoForm.country.invalid &&
                        addInfoForm.country.touched
                    }"
                  >
                    <option value="" selected>{{'placeholder.Select'|translate}} {{'formlabel.Country'|translate}}</option>
                    <option
                      *ngFor="let country of countryList"
                      [value]="country.name"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <div class="error-div">
                  <app-show-errors
                    [ctrl]="addressInfoForm.get('country')"
                    [fieldLabel]="'Country name'"
                  >
                  </app-show-errors>
                </div>
                <div class="inputMain">
                  <label class="labelForm">{{'formlabel.State'|translate}}*</label>
                  <select
                    class="inputType"
                    formControlName="state"
                    [ngClass]="{
                      'is-invalid':
                        addInfoForm.state.invalid && addInfoForm.state.touched
                    }"
                  >
                    <option value="" selected>{{'placeholder.Select'|translate}} {{'formlabel.State'|translate}}</option>
                    <option
                      *ngFor="let state of stateList"
                      [value]="state.name"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                </div>
                <div class="error-div">
                  <app-show-errors
                    [ctrl]="addressInfoForm.get('state')"
                    [fieldLabel]="'State name'"
                  >
                  </app-show-errors>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="btnContainer">
      <button class="cancelBtn" (click)="closeTemplateModal(cdkPortal)">
        {{'addressUpdate.CANCEL'|translate}}
      </button>
      <button class="submitBtn" (click)="submitAddressForm(cdkPortal)">
        {{'addressUpdate.SUBMIT'|translate}}        
      </button>
    </div>
  </div>
</ng-template>
