export var api = {
  auth: {
    login: 'auth/login',
    logout: 'auth/logout',
    userInfo: 'auth/me',
    forgotPassword: 'auth/forgot_password',
    register: 'auth/register',
    resetPassword: 'auth/reset_password',
    changePassword: 'auth/change_password',
    sendVerifyEmail: 'auth/send-verify-email',
    verfiyMailToken: 'auth/verify-email/:token',
    update_info: 'auth/update_info',
    saveFcmToken: 'auth/save_fcm_token',
    savePreferences: 'auth/save_preferences',
    deleteAccount: 'auth/delete_account',
    savePreferredLanguage:'auth/save_preferred_language'
  },
  geo: {
    allCountry: 'geo/countries',
    state: 'geo/states/:country_id',
    city: 'geo/cities/:state_id',
  },
  home: {
    masterData: 'home/index',
    search: 'home/search?search=searchKeyword',
  },
  pseller: {
    register: 'seller/register', // Use it while loggedin user role is 'customer'
    updateInfo: 'seller/update_info', // Use it while loggedin user role is already 'seller'
    imageData: 'seller/upload-photoid',
    updateProfile: 'seller/update_profile',
  },
  product: {
    contact: 'products/enquiry',
    list: 'products/list',
    create: 'products/create',
    details: 'products/view/:slug',
    message: 'products/enquiry-listing',
    messageDetailList: 'products/enquiry/:id',
    markasRead: 'products/enquiry/mark_as_read/:id',
    markasArcheive: 'products/enquiry/mark_as_archived/:id',
    messageAnswer: 'products/enquiry/answer/:id',
    messageChatReply: 'products/enquiry/chat',
    myProducts: 'products/my-products',
    createByCsv: 'products/create-by-csv',
    productCommission: 'system_settings/view/platform_commission_percentage',
    UpdateById: 'products/update/:id',
    viewByPayment: 'products/view-by-payment/:uuid',
    approvedSuggestedPrice: 'products/get_suggested_price/:product_id',
    upload_images: 'products/upload_images/:id',
    category: 'products/category/:id',
    createByExecel: 'products/create-by-execel',
    addToFavorite: 'products/add-to-favorite',
    myFavorite: 'products/my-favorite',
    myFavoriteIds: 'products/my-favorite-ids',
    updateStatus: 'products/update-status/:id',
    attributeList:'products/attributes'
  },
  dealer: {
    register: 'dealer/register',
    profile: 'dealer/get_profile',
    update_info: 'dealer/update_info',
    imageData: 'dealer/upload-photoid',
    transactions: 'dealer/transactions?from=fromDate&to=toDate',
    update_payout_info: 'dealer/update_payout_info'
  },
  order: {
    createOrderRequest: 'orders/create-request',
    viewOrder: 'orders/view/:id',
    myOrders: 'orders/my-orders',
    sellerDealerOrders: 'orders/seller-dealer-orders',
    reviewRequest: 'orders/review-request',
    payment: 'payment/charge',
    stripeCards: 'payment/cards/all',
    viewByPayment: 'orders/view-by-payment/:uuid',
    myEarnings: 'orders/my-earnings',
    shipping: 'orders/ship/:id',
    download: 'orders/download/:order_id',
    createCard: 'payment/cards/create',
    trackingInfo: 'orders/tracking_info/:id',
    shippingPricing: 'orders/shipping_pricing/:country_code',
    getNearestServicePoints: 'orders/get_nearest_service_points',
    getServicePointDetails: 'orders/get_service_point_details/:id/:type'
  },
  subscription: {
    plans: 'subscription_plans/active',
    payment: 'payment/subscription_charge',
  },
  newsletter: {
    subscribe: 'newsletter/subscribe',
  },
  frequentlyAskedQue: {
    faqs: 'home/faqs',
  },
  address: {
    update_address: 'address/update_address/:address_id',
    create_address: 'address/create',
  },
  notification: {
    notify_user: 'notifications/latest',
    mark_user: 'notifications/markAsRead',
    notification_paginated: 'notifications/paginated'
  },
  articles: {
    articleList: 'articles/list',
    articleView: 'articles/view/:slug'
  },
  systemSetting: {
    systemSetting: 'system_settings/all'
  },
  paymentWithBancontact: {
    createPaymentIntent: 'payment/create-payment-intent',
    getPaymentIntent: 'payment/get-payment-intent/:intent_id',
    bancontactCharge: 'payment/bancontact_charge'
  }
};