import { TemplatePortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TemplateModalOverlayRef } from '@features/customCDK/cdkCustomMdl/classes/template-modal.ref';
import {
  CustomOverlayConfig,
  TemplateModalService,
} from '@features/customCDK/cdkCustomMdl/services/template-modal.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-size-chart-modal',
  templateUrl: './size-chart-modal.component.html',
  styleUrls: ['./size-chart-modal.component.scss'],
})
export class SizeChartModalComponent implements OnInit {
  @ViewChild('modalTemplate') modalTemplate!: TemplatePortal<any>;
  private _unsubscribeAll: Subject<any> = new Subject();
  private templateRef!: TemplateModalOverlayRef;
  @Output() addressDetailsSubmit = new EventEmitter();
  customConfig!: CustomOverlayConfig;
  @Input() addressDetails = null;
  chartType: any = 'clothes';
  constructor(private _TemplateModalService: TemplateModalService) {
    //get all country list for bind country dropdown
  }

  ngOnInit(): void {}

  ngOnChanges(): void {}

  openSizeChartModal(type) {
    this.chartType = type;
    this.templateRef = this._TemplateModalService.open(
      this.modalTemplate,
      {},
      this.customConfig
    );
  }

  closeTemplateModal($event?: CustomOverlayConfig) {
    this.templateRef.close(this.customConfig);
  }
}
