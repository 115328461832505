import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LimitInputDirective } from './limit-to-input/limit-input.directive';
import { InputRestrictionDirective } from './InputRestriction/input-restriction.directive';
import { TwoDigitDecimalNumberDirective } from './two-digit-decimal-number.directive';

@NgModule({
  declarations: [
    LimitInputDirective,
    InputRestrictionDirective,
    TwoDigitDecimalNumberDirective,
  ],
  imports: [CommonModule],
  exports: [
    LimitInputDirective,
    InputRestrictionDirective,
    TwoDigitDecimalNumberDirective
  ],
  providers: [],
})
export class CoreDirectivesModule {}
