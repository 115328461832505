import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreventRouteGuard } from '@core/guards/prevent.guard';
import { VerifyEmailComponent } from '@views/common/verify-email/verify-email.component';

import { AuthLayoutComponent } from './master-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './master-layout/main-layout/main-layout.component';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: ''
  // },
  { path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [PreventRouteGuard],
    loadChildren: () => import('./master-layout/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule),
    data: { title: 'Auth' }
  },
  {
    path: '',
    component: MainLayoutComponent,
    loadChildren: () => import('./master-layout/main-layout/main-layout.module').then(m => m.MainLayoutModule),
    data: { title: 'Home' }
  },
  {
    path: 'verify-email/:token',
    component: VerifyEmailComponent
  },
  /* MOBILE SPECIFIC ROUTES */
  {
    path: 'contact-us',
    loadChildren: () => import('@views/common/contact/contact.module').then(m => m.ContactModule),
    data: { title: 'Contact Us' }
  },
  /* END MOBILE SPECIFIC ROUTES */
  {
    path: '**',
    redirectTo: '/auth/login',
    data: { title: 'Login' }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
