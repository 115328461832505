import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { ProgressStepComponent } from './progress-step.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [FormsModule,CommonModule],
  declarations: [ProgressStepComponent],
  bootstrap: [ProgressStepComponent],
  exports: [ProgressStepComponent]
})
export class ProgressStepModule { }