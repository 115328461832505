import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from '@core/config';
import { BaseService } from '@core/services/base.service';
import { CryptoService } from '@core/services/crypto.service';
import { resolve } from 'dns';

@Injectable({
  providedIn: 'root',
})
export class AuthAPIService {
  constructor(private _api: BaseService, private _crypto: CryptoService) { }

  userLogin(formData: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let body = await this._crypto.encrypt(JSON.stringify({ ...formData }));
      body = 'data=' + encodeURIComponent(body);

      console.log('Login Body Data :: ', body);

      this._api.post(api.auth.login, body).subscribe((res: any) => {
        console.log('Login Resp ', res);
        if (res instanceof HttpErrorResponse) {
          reject(res.error);
        } else {
          resolve(res);
        }
      });
    });
  }

  userRegistration(formData: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let body = await this._crypto.encrypt(JSON.stringify({ ...formData }));
      body = 'data=' + encodeURIComponent(body);

      this._api.post(api.auth.register, body).subscribe((res: any) => {
        console.log('Register Resp ', res);
        if (res instanceof HttpErrorResponse) {
          reject(res.error);
        } else {
          resolve(res);
        }
      });
    });
  }

  forgotPassword(formData: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let body = await this._crypto.encrypt(JSON.stringify({ ...formData }));
      body = 'data=' + encodeURIComponent(body);

      this._api.post(api.auth.forgotPassword, body).subscribe((res: any) => {
        console.log('Forgot Pswd Resp ', res);
        if (res instanceof HttpErrorResponse && res.status == 200) {
          reject(res.error);
        } else {
          resolve(res);
        }
      });
    });
  }

  resetPassword(formData: any, token: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this._api
        .post(api.auth.resetPassword, {
          password: formData.password,
          token: token,
        })
        .subscribe((res: any) => {
          if (res && res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        });
    });
  }

  verfiyEmail(token: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this._api
        .post(api.auth.verfiyMailToken.replace(':token', token), {})
        .subscribe((res: any) => {
          console.log('verify user mail >>', res);
          if (res && res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        });
    });
  }

  subscribeNewsLetter(formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._api
        .post(api.newsletter.subscribe, formData)
        .subscribe((res: any) => {
          if (res && res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        });
    });
  }

  saveFcmToken(token: any, device_id: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this._api
        .post(api.auth.saveFcmToken, {
          token: token,
          device_id: device_id,
        })
        .subscribe((res: any) => {
          if (res && res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        });
    });
  }

  savePrferences(formData: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this._api
        .post(api.auth.savePreferences, formData)
        .subscribe((res: any) => {
          if (res && res.error) {
            reject(res);
          } else {
            resolve(res);
          }
        });
    });
  }

  savePreferredLanguage(preferredLanguage: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this._api
        .post(api.auth.savePreferredLanguage, {
          preferred_language: preferredLanguage
        })
        .subscribe((res: any) => {
          if (res && res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        });
    });
  }

}
