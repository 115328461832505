import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageUploadComponent } from './image-upload.component';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  declarations: [ImageUploadComponent],
  bootstrap: [ImageUploadComponent],
  imports: [CommonModule],
  exports: [ImageUploadComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})

export class ImageUploadModule { }
