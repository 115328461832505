import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { ProgressStepperComponent } from './progress-stepper.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [FormsModule, CommonModule],
  declarations: [ProgressStepperComponent],
  exports: [ProgressStepperComponent]
})
export class ProgressStepperModule { }
