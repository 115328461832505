<div style=" display: flex ; flex-direction: row ; align-items: baseline;">
    <div [ngClass]="{
        link: status == 'complete',
        'selected-link': status == 'inprogress',
        'incomplete-link': status == 'incomplete'
      }"></div>

    <div style="max-width: 17px; display: flex ; flex-direction: column ;">
        <div [ngClass]="{
          dot: status == 'complete',
          'selected-dot': status == 'inprogress',
          'incomplete-dot': status == 'incomplete'
        }">
            <!-- <span [innerHtml]="getSvg(icon)"></span> -->
            <img [src]="(icon)">
        </div>
        <div class="name-container" *ngIf="status == 'inprogress'">
            <p class="mat-body-2">{{ name }}</p>
        </div>
    </div>
</div>