import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { MockTask } from '../mocks/mocktasks';

@Component({
  selector: 'progress-step',
  templateUrl: './progress-step.component.html',
  styleUrls: [ './progress-step.component.scss' ]
})
export class ProgressStepComponent implements OnInit  {
  @Input() status: string;
  @Input() name: string;
  @Input() icon: any;
  constructor(protected sanitizer: DomSanitizer) {
    // this.icon = this.sanitizer.bypassSecurityTrustResourceUrl(this.icon);
  }
  
  ngOnInit() {
    // this.icon = `<img src=${this.icon}>`
    console.log('icon',this.icon);
  }

  getSvg(icon) {
    return `<img src=${icon}>`
  }
}