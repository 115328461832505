import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Alert, AlertType, AlertOption } from '@core/interfaces/alert.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private subject = new Subject<Alert>();
  private defaultId = 'default-alert';
  constructor() { }

  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
  }

  // convenience methods
  success(message: string, options?: AlertOption) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      title: message,
      timer: 5000, //options?.timer,
      timerProgressBar: true, //options?.timer ? true : false,
      icon: 'success',
      showConfirmButton: options?.showConfirmButton,
      showCloseButton: true,
      // showClass: {
      //   popup: 'animate__slideInRight'
      // },
      // hideClass: {
      //   popup: 'animate__slideOutRight'
      // }
    });
  }

  error(message: string, options?: AlertOption) {
    Swal.fire({
      text: message,
      timer: options?.timer,
      timerProgressBar: options?.timer ? true : false,
      // icon: 'error',
      imageUrl: 'assets/images/info.svg',
      imageHeight: 50,
      imageWidth: 50,
      showConfirmButton: options?.showConfirmButton,
      backdrop: !options?.allowOutsideClick,
      allowOutsideClick: options?.allowOutsideClick || true,
      // showClass: {
      //   backdrop: 'swal2-backdrop-show'
      // },
      // hideClass: {
      //   popup: 'animate__bounceOut'
      // }
    });
    return false;
  }
  errorHtml(message: string, options?: AlertOption) {
    Swal.fire({
      html: message,
      timer: options?.timer,
      timerProgressBar: options?.timer ? true : false,
      icon: options.icon ? options.icon : null, // 'error'
      showConfirmButton: options?.showConfirmButton,
      showCloseButton: true,
      backdrop: !options?.allowOutsideClick,
      allowOutsideClick: options?.allowOutsideClick || true,
      // showClass: {
      //   backdrop: 'swal2-backdrop-show'
      // },
      // hideClass: {
      //   popup: 'animate__bounceOut'
      // }
    });
    return false;
  }

  info(message: string, options?: AlertOption) {
    Swal.fire({
      text: message,
      timer: options?.timer,
      timerProgressBar: options?.timer ? true : false,
      icon: 'info',
      showConfirmButton: options?.showConfirmButton,
      showCloseButton: false,
      // showClass: {
      //   popup: 'animate__bounceIn'
      // },
      // hideClass: {
      //   popup: 'animate__bounceOut'
      // }
    });
  }
  successalert(message: string, options?: AlertOption) {
    Swal.fire({
      text: message,
      timer: options?.timer,
      timerProgressBar: options?.timer ? true : false,
      icon: 'success',
      showConfirmButton: options?.showConfirmButton,
      showCloseButton: true,
      // showClass: {
      //   popup: 'animate__bounceIn'
      // },
      // hideClass: {
      //   popup: 'animate__bounceOut'
      // }
    });
  }

  warn(message: string, options?: AlertOption, callback?: any) {
    Swal.fire({
      text: message,
      timer: options?.timer,
      timerProgressBar: options?.timer ? true : false,
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      confirmButtonText: options?.showTextButton ?? 'Ok',
      showConfirmButton: options?.showConfirmButton,
      showCloseButton: false,
      // showClass: {
      //   popup: 'animate__bounceIn'
      // },
      // hideClass: {
      //   popup: 'animate__bounceOut'
      // }
    }).then((result) => {
      if (result.isConfirmed) {
        callback(1);
      }
    });
  }

  // main alert method
  alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  // clear alerts
  clear(id = this.defaultId) {
    this.subject.next(new Alert({ id }));
  }

  alertwithtml(
    title: string,
    message: string,
    options?: AlertOption,
    callback?: any
  ) {
    Swal.fire({
      title: title,
      icon: options?.icon,
      html: message,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK',
      backdrop: !options?.allowOutsideClick,
      allowOutsideClick: options?.allowOutsideClick || true,
    }).then((result) => {
      if (result.isConfirmed) {
        callback(1);
      }
    });
  }

  alertWitHtmlNew(
    options?: Partial<SweetAlertOptions>
  ): Promise<SweetAlertResult> {
    let obj: Partial<SweetAlertOptions> = {
      icon: 'info', //`'warning'`, `'error'`, `'success'`, `'info'` and `'question'`
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK',
      backdrop: !options?.allowOutsideClick,
      allowOutsideClick: true,
    };
    obj = {
      ...obj,
      ...options,
    };
    return Swal.fire(obj);
  }
  
  alertOk(
    title: string,
    message: string,
    options?: AlertOption | any | null,
    callback?: any
  ) {
    Swal.fire({
      title: title,
      icon: 'warning',
      html: message,
      showCloseButton: options?.showCloseButton ?? true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK',
      backdrop: !options?.allowOutsideClick,
      allowOutsideClick: options?.allowOutsideClick ?? true,
    }).then((result) => {
      if (result.isConfirmed) {
        callback(1);
      }
    });
  }

  popWitHtml(options?: Partial<SweetAlertOptions>): Promise<SweetAlertResult> {
    let obj: Partial<SweetAlertOptions> = {
      icon: 'info', //`'warning'`, `'error'`, `'success'`, `'info'` and `'question'`
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK',
      backdrop: !options?.allowOutsideClick,
      allowOutsideClick: true,
    };
    obj = {
      ...obj,
      ...options,
    };
    let d: any = Swal;
    d = Swal.fire(obj)
    return d;
  }
}
