import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { api } from '@core/config';
import { CryptoService } from './crypto.service';
import { LocalStorageService } from './local-storage.service';
import { BaseService } from './base.service';
import { environment } from 'src/environments/environment';
import { delay } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	username: string = '';
	tempPassword: string = '';
	loggedInUser: any | null = null;
	auth_token: string | null = '';
	user_id: number = 0;
	isLogoutClicked: boolean = false;

	private loggedInUserdataSource: any = new BehaviorSubject<any>(1);
	isLoggedInUser$ = this.loggedInUserdataSource.asObservable();

	constructor(
		private _http: HttpClient,
		private _api: BaseService,
		private _crypto: CryptoService,
		private _store: LocalStorageService,
	) { }

	/* Manual encryption decryption happen for access token storage in localstore */
	setAuthorizationToken(_token: string) {
		localStorage.removeItem('access_token');
		this.auth_token = this._crypto.encrypt(_token);
		if (this.auth_token) localStorage.setItem('access_token', this.auth_token);
	}

	getAuthorizationToken() {
		this.auth_token = localStorage.getItem('access_token');
		if (this.auth_token) {
			this.auth_token = this._crypto.decrypt(this.auth_token);
		}
		return this.auth_token ? this.auth_token : '';
	}

	removeAuthorizationToken() {
		this.auth_token = '';
		localStorage.removeItem('access_token');
	}

	getUserId() {
		this.loggedInUser = this._store.getData('userDetails');
		return this.loggedInUser && this.loggedInUser.id ? this.loggedInUser.id : null;
	}

	getUserRole() {
		this.loggedInUser = this._store.getData('userDetails');
		return this.loggedInUser && this.loggedInUser.role ? this.loggedInUser.role : null;
	}

	/* Use to check dealer account subscription status */
	getAccountServiceEnabled() {
		let user: any = this._store.getData('userDetails');
		return user && user.subscription_status == "active" ? true : false;
	}

	saveUser(_user: any) {
		localStorage.removeItem('userDetails');
		this.loggedInUser = _user;
		// localStorage.setItem('loggedInUser', JSON.stringify(this.loggedInUser));
		this._store.saveData('userDetails', _user || {});
	}

	getUser() {
		let user = localStorage.getItem('loggedInUser');
		this.loggedInUser = user ? JSON.parse(user) : null;
		return this.loggedInUser;
	}

	clearLoggedInUser() {
		this.loggedInUser = {};
		this.user_id = 0;
	}

	clearStorage() {
		this.loggedInUser = null;
		this.auth_token = '';
		this.user_id = 0;
		localStorage.clear();
	}

	checkIfLoggedIn() {
		var token = this.getAuthorizationToken();
		if (token && token.length > 0) {
			return true;
		}
		return false;
	}

	getIsLogoutClicked(): boolean {
		return this.isLogoutClicked;
	}

	changeIsLogoutClicked(val: boolean) {
		this.isLogoutClicked = val;
	}

	/* validateToken(_token: string) {
		return this._http.post(api.VALIDATE_TOKEN, { token: _token });
	} */

	async load(): Promise<any> {
		if (this.checkIfLoggedIn()) {
			return this._api.get(api.auth.userInfo).toPromise();
		} else {
			return new Promise((res: any, rej: any) => {
				rej({});
			});
		}
	}

	async logout(): Promise<any> {
		if (this.checkIfLoggedIn()) {
			let headers: any = new HttpHeaders().set('Content-Type', 'application/json');
			headers.append('Authorization', 'Bearer ' + this.getAuthorizationToken())

			return this._http.post(`${environment.baseURL}${environment.apiURL}${api.auth.logout}`, {}, { headers: headers }).toPromise();
		} else {
			return new Promise((res: any, rej: any) => {
				rej({});
			});
		}
	}

	async reloadUser(): Promise<any> {
		let data = await this.load();
		console.log('reloadUser data >>', data);

		if (data && !data.error) {
			this.saveUser(data);
		}
		return data;
	}

	sendVerifyEmail(formData: any): Promise<any> {
		return new Promise(async (resolve, reject) => {
			await this._api.post(api.auth.sendVerifyEmail, formData).subscribe((res: any) => {
				if (res) {
					resolve(res);
				} else {
					reject(res);
				}
			});
		});
	}
	
}
