import { ElementRef, Injectable, OnInit } from '@angular/core';
import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { config } from '@core/config';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

// export function identity<Type>(arg: Type): Type {
//   return arg;
// }

type DescribableFunction = (v: object) => void;

@Injectable({
  providedIn: 'root',
})
export class UtilityService implements OnInit {
  constructor(
    private _router: Router,
    private _location: Location,
    private _translate: TranslateService
  ) { }

  ngOnInit(): void {
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  generateReference() {
    let currentdate = new Date();
    return (
      currentdate.getDate() +
      '' +
      (currentdate.getMonth() + 1) +
      '' +
      currentdate.getFullYear() +
      '' +
      currentdate.getHours() +
      '' +
      currentdate.getMinutes() +
      '' +
      currentdate.getSeconds()
    );
  }

  // USEFUL TO CHECK MULTIPLE "OR" CONDITIONS WITH DIFFERENT VALUES
  doesIncludes(valueToMatch: any, dataSet: any) {
    return _.includes(dataSet, valueToMatch);
  }

  filterArray(data: any, value: string) {
    return _.filter(data, (o) => _.includes(o, value));
  }

  filterObjectFromArray(
    arrayList: any[],
    value: { [key: string]: any } | DescribableFunction
  ): any[] {
    return _.filter(arrayList, value);
  }

  findObjectValue(objArrayList: any, search: {}) {
    return _.find(objArrayList, search);
  }

  filterMultiValue(searchArray: any, txnId: any, productId: any) {
    let output: any = '';
    const data = _.filter(searchArray, ({ TransactionTypeID, ProductTypeID }) =>
      _.every([
        _.includes(txnId.toString(), TransactionTypeID),
        _.includes(productId, ProductTypeID),
      ])
    );
    if (data.length > 0) {
      output = _.filter(data, { TransactionTypeID: txnId.toString() });
    }
    if (output) {
      return output[0];
    }
    return false;
  }

  filterArrayAndGetUniqueKeyValueList(
    array: { [key: string]: any }[],
    keyName: string
  ) {
    return [...new Set(array.map((item: any) => item[keyName]))];
  }

  /* Used to highlight invalid form fields, also work for n no. of child fields */
  markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) this.markFormGroupTouched(control);
    });
  }

  isValidMobileNumber(mobileValue: any) {
    var filter = /^[6789][0-9]{9}/;
    if (!filter.test(mobileValue)) return false;
    else return true;
  }

  findRechargeCharges(list: any, chargesCode: any) {
    return _.filter(
      list,
      ({ code }) => code.toLowerCase().indexOf(chargesCode.toLowerCase()) != -1
    );
  }

  objectReplace(original: any, current: any) {
    return _.merge(original, _.pick(current, _.keys(original)));
  }

  mergeObj(objArray: any) {
    return objArray.forEach((result: any) => {
      return _.merge(result);
    });
  }

  padwithzeroes(number: string, length: number) {
    var my_string = '' + number;
    while (my_string.length < length) {
      my_string = '0' + my_string;
    }
    return my_string;
  }

  padding_right(s: any, c: any, n: any) {
    if (!s || !c || s.length >= n) {
      return s;
    }
    var max = (n - s.length) / c.length;
    for (var i = 0; i < max; i++) {
      s += c;
    }
    return s;
  }

  AsciitoHex(str: string) {
    var hex = '';
    var len = str.length;

    for (var i = 0; i < len; i++) {
      var a = str.charCodeAt(i);
      var h = a.toString(16);
      if (len == 0) return;
      //if (h.length == 1) h = '0' + h;
      hex += h;
    }
    return hex;
  }
  GetSetHHMMSS = () => {
    let d = new Date();
    let hours = this.format_two_digits(d.getHours());
    let minutes = this.format_two_digits(d.getMinutes());
    let seconds = this.format_two_digits(d.getSeconds());
    let time = hours + '' + minutes + '' + seconds;
    return time;
  };
  format_two_digits = (n: any) => {
    return n < 10 ? '0' + n : n;
  };

  // Ascending Sort
  sortAscending(array: any, sortFieldArr: any) {
    return _.sortBy(array, sortFieldArr);
  }

  calcAge(birthDate: any) {
    if (birthDate == undefined || birthDate == '') {
      return;
    }
    let todayDate = new Date(),
      todayYear = todayDate.getFullYear(),
      todayMonth = todayDate.getMonth(),
      todayDay = todayDate.getDate(),
      birthDay = parseInt(birthDate.split('/')[0]),
      birthMonth = parseInt(birthDate.split('/')[1]),
      birthYear = parseInt(birthDate.split('/')[2]);
    let age: any = todayYear - birthYear;
    let age_month = todayMonth - birthMonth + 1;
    let age_day = todayDay - birthDay;

    if (age_month < 0 || (age_month == 0 && age_day < 0)) {
      age = parseInt(age) - 1;
    }

    if (age < 18) {
      // BAlert("Exclam", "Customer must be 18 years old !");
      return false;
    }
    return age;
  }

  // USED TO CONVERT IN MASKED VALUE BY GIVEN NO OF CHAR.
  MaskDataFields(
    inputString: any,
    noOfChars: any,
    replaceString: any,
    islast: any
  ): any {

    if (inputString) {
      inputString = inputString.toString().trim();
      if (islast)
        return (
          new Array(inputString.length - (noOfChars - 1)).join(replaceString) +
          inputString.substr(inputString.length - noOfChars, noOfChars)
        );
      else
        return (
          inputString.substr(0, noOfChars) +
          new Array(inputString.length - (noOfChars - 1)).join(replaceString)
        );
    }
  }

  /* generatePDF(
    invoiceElement: any,
    filename: any,
    isType: string = 'DOWNLOAD',
    customPage?: boolean
  ): void {
    html2canvas(invoiceElement, { scale: 3 }).then((canvas) => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
      const fileWidth = 200;
      const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
      let page: any = 'a4'
      if (customPage) {
        page = [fileWidth + 20, generatedImageHeight]
      }
      let PDF = new jsPDF('p', 'mm', page);
      PDF.addImage(
        imageGeneratedFromTemplate,
        'PNG',
        5,
        5,
        fileWidth,
        generatedImageHeight,
        '',
        'MEDIUM'
      );
      PDF.rect(
        5,
        5,
        PDF.internal.pageSize.width - 10,
        PDF.internal.pageSize.height - 10,
        'S'
      );
      PDF.html(invoiceElement.innerHTML);
      if (isType == 'DOWNLOAD') {
        PDF.save(`${filename}.pdf`);
      } else if (isType == 'PRINT') {
        PDF.autoPrint();
        window.open(PDF.output('bloburl'), '_blank');
      }
    });
  }
  printPDF(invoiceElement: any, cssEle?: any) {
    const WindowPrt: any = window.open(
      '',
      '',
      'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0'
    );
    const css = `${cssEle}`;
    const pageContent = `<!DOCTYPE html><html><head>${css}</head><body>${invoiceElement.innerHTML}</body></html>`;

    WindowPrt.document.write(pageContent);
    WindowPrt.document.close();
    WindowPrt.focus();

    setTimeout(() => {
      WindowPrt.print();
      WindowPrt.close();
    }, 2000);
  } */

  checkFormControlAvailable(formGroup: UntypedFormGroup, controlName: string) {
    return Object.keys(formGroup.controls).find((name: any) => {
      return formGroup.get(controlName) === formGroup.controls[name];
    });
    // Object.keys(formGroup).find(name => formGroup.get(controlName) === formGroup[name]
  }
  setRemoveValidator(
    type: 'r' | 'a', //'r' remove | 'a' add
    group: UntypedFormGroup,
    control: string | string[],
    validators: ValidatorFn | ValidatorFn[],
    checkHasInForm: boolean = false
  ) {

    let controls: string[] = typeof control === 'string' ? (control === 'all' ? Object.keys(group.value)
      : [control]) : control;
    controls.forEach((controlName) => {
      if (
        checkHasInForm &&
        !this.checkFormControlAvailable(group, controlName)
      ) {
        return;
      }
      if (type === 'a') {
        group.controls[controlName].setValidators(validators);
      } else if (type === 'r') {
        group.controls[controlName].clearValidators();
      }
      group.controls[controlName].updateValueAndValidity();
    });
  }

  _mergeobject(data: any, mergedata: any) {
    return _.map(data, (res) => {
      _.each(mergedata, (array: any) => {
        res[array.field1 + '' + array.field2] =
          res[array.field1] + ' | ' + res[array.field2];
      });
      return res;
    });
  }

  removeKeyFromObj(object: {}, keys: string | string[]) {
    return _.omit(object, [...keys]);
  }

  getMonthsList() {
    let listpayoutmonth: any[] = [];
    let monthName = new Array(
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    );
    let d: any = new Date();
    let month = d.getMonth();
    let year = d.getFullYear();
    for (let i = 1; i <= 12; i++) {
      listpayoutmonth.push({ month: monthName[month] + ' ' + year });
      if (month < 1) {
        month = 11;
        year = d.getFullYear(d.setFullYear(d.getFullYear() - 1));
      } else {
        month = month - 1;
      }
    }
    return listpayoutmonth;
  }

  getFinYearList() {
    let Finyear = new Date().getFullYear();
    let Finyearrange = [];
    for (let k = 0; k < 2; k++) {
      Finyearrange.push({
        label:
          Finyear - k - 1 + '-' + parseInt(String(Finyear - k).slice(2, 4)),
      });
    }
    return Finyearrange;
  }
  generateBitMap(
    bitmap: string,
    mstAuthenticationOverride: any,
    newAuthId: any,
    userClass: string,
    TransactionType: string
  ) {
    let object = {
      Auth_id: newAuthId,
      otpSend: newAuthId,
      bitmap: bitmap,
    };
    const splitBits = bitmap.split('');
    console.log('mstAuthenticationOverride >>', {
      AddCIf: splitBits[1],
      AddAccount: splitBits[2],
      AddBene: splitBits[3],
      PostTran: splitBits[5],
    });
    const AuthenticationOverrideMatrix = _.filter(
      _.filter(mstAuthenticationOverride, {
        ChannelID: 8,
        ProductID: '9999',
        UserTypeName: userClass,
        TransactionType: TransactionType,
      }),
      {
        AddCIf: splitBits[1],
        AddAccount: splitBits[2],
        AddBene: splitBits[3],
        PostTran: splitBits[5],
      }
    );
    if (AuthenticationOverrideMatrix.length !== 0) {
      if (AuthenticationOverrideMatrix[0].AuthTypeID) {
        object.Auth_id = AuthenticationOverrideMatrix[0].AuthTypeID;
        object.otpSend = 1;
        object.bitmap = '10000100';
      } else {
        object.Auth_id = AuthenticationOverrideMatrix[0].AuthTypeID;
        object.otpSend = 0;
        object.bitmap = '00000100';
      }
    }
    return object;
  }

  isJsonStringified(value) {
    try {
      const isObject = value.slice(0, 1) === '{' && value.slice(value.length - 1) === '}';
      if (typeof value === 'string' && isObject) {
        JSON.parse(value);
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
    return true;
  }

  _navigate(path: string, params?: Object) {
    this._router.navigate([path], params); /* { skipLocationChange: true } */
  }

  _navigateWithReload(path: string, params?: Object) {
    this._router.navigate([path], params)
      .then(() => {
        window.location.reload();
      });
  }

  _navigateBack() {
    this._location.back();
  }

  // function to convert  base64 string to file
  dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // function to convert file to base64 string
  toBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error: any) => reject(error);
  });

  generateValidationErrorMsg(errorObj: any) {
    let messageHtml = '<p style="text-align: center; color: red;">' + errorObj.message + '</p><br/>';
    Object.keys(errorObj.errors).filter((field: any) => {
      messageHtml += '<p style="text-align: center;">' + errorObj.errors[field] + '</p><br/>'
    });
    return messageHtml
  }

  formatBytes(a, b = 2) {
    if (!+a) return "0 Bytes";
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1024));
    return `${parseFloat((a / Math.pow(1024, d)).toFixed(c))} ${["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]}`
  }

  public sortOrderByName(array, key) {
    return array.sort((a, b) => a[key] ? a[key].trim() : '' > b[key] ? b[key].trim() : '' ? 1 : -1)
  }

  public getTranslatedMessage(objName: any, keyName: any): string {
    var name = "";
    this._translate.get(objName).subscribe((data: any) => {
      name = data[keyName];
    });
    return name;
  }
}
