import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  private _breakpointObserver: BehaviorSubject<any>;
  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    this._breakpointObserver = new BehaviorSubject(null);
    this.breakpointObserver.observe(["(min-width: 768px)"]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this._breakpointObserver.next(false);
      } else {
        this._breakpointObserver.next(true);
      }
    });
  }

  set BreakpointObserver(flag: any) {
    this._breakpointObserver.next(flag)
  }

  get BreakpointObserver(): any | Observable<any> {
    return this._breakpointObserver.asObservable();
  }
}
