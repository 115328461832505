import { Injectable } from '@angular/core';
import { CryptoService } from './crypto.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    private _crypto: CryptoService,
    private _utility: UtilityService
  ) { }

  public saveData(key: string, value: any) {
    let data = JSON.stringify(value)
    localStorage.setItem(key, this._crypto.encrypt(data));
  }

  public getData(key: string) {
    let data = localStorage.getItem(key);
    if (data) {
      data = this._crypto.decrypt(data);
      if (this._utility.isJsonStringified(data)) {
        data = JSON.parse(data);
      }
    }
    return data
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  public saveSystemSetting(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  public getSystemSetting(key: string) {
    return localStorage.getItem(key);
  }
}
