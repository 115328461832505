import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash'
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class CacheDataService {
  private _masterDownloadData: BehaviorSubject<any>;
  private _notifications: BehaviorSubject<any>;
  private _systemSettingData: BehaviorSubject<any>;
  private _manageListingSettingData: BehaviorSubject<any>;
  constructor(
    private _utility: UtilityService
  ) {
    this._masterDownloadData = new BehaviorSubject(null);
    this._notifications = new BehaviorSubject(null);
    this._systemSettingData = new BehaviorSubject(null);
    this._manageListingSettingData = new BehaviorSubject(null);
  }


  // set masterDownloadData(data: any) {
  //   let details = this._masterDownloadData.getValue();
  //   details = _.merge({}, details, data);
  //   this._masterDownloadData.next(details);
  // }

  get masterDownloadData(): any | Observable<any> {
    return this._masterDownloadData.asObservable();
  }

  set notifications(data: any) {
    this._notifications.next(data);
  }

  get notificaitons(): any | Observable<any> {
    return this._notifications.asObservable();
  }

  set masterDownloadData(data: any) {
    let details: any = this._masterDownloadData.getValue();
    if (data) {
      data.all_brands = data.all_brands != null ? this._utility.sortOrderByName(data.all_brands, 'name') : data.all_brands;
      data.all_categories = this._utility.sortOrderByName(data.all_categories, 'name');
      data.all_countries = this._utility.sortOrderByName(data.all_countries, 'name');
      data.sponsors = this._utility.sortOrderByName(data.sponsors, 'name');
    }
    details = _.merge({}, details, data);
    console.log("set masterDownloadData", details);
    this._masterDownloadData.next(details);
  }

  get systemSettingData(): any | Observable<any> {
    return this._systemSettingData.asObservable();
  }

  set systemSettingData(data: any) {
    this._systemSettingData.next(data);
  }

  get manageListingSettingData(): any | Observable<any> {
    return this._manageListingSettingData.asObservable();
  }

  set manageListingSettingData(data: any) {
    this._manageListingSettingData.next(data);
  }
}
