import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { CookieService } from 'ngx-cookie-service';
import { AsyncPipe, CurrencyPipe, DatePipe } from '@angular/common';

import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from '@angular/common/http';
import { AuthHeaderInterceptor } from '@core/interceptor/auth-header.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AuthLayoutModule } from './master-layout/auth-layout/auth-layout.module';
import { MainLayoutModule } from './master-layout/main-layout/main-layout.module';
import { LoaderComponent } from './components/loader/loader.component';
import { ResetPasswordComponent } from './views/Auth/reset-password/reset-password.component';
import { VerifyEmailComponent } from './views/common/verify-email/verify-email.component';
import { MessagingService } from './service/messaging.service';
// import { AngularFireModule } from '@angular/fire';
// import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { I18nModule } from './i18n/i18n.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { Meta } from '@angular/platform-browser';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname, // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#005ea5',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#0b0c0c',
      text: '#ffffff',
      border: 'transparent',
    },
  },
  type: 'opt-out',
  content: {
    message:
    'This website stores cookie on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our ',
    dismiss: 'Accept',
    deny: 'Decline',
    link: 'Privacy Policy.',
    href: '/privacy-policy',
    policy: 'Cookie Policy',
    cookieconsent_dismissed: 'yes',
    close: '&#x274c;',
  },
};

@NgModule({
  declarations: [AppComponent, LoaderComponent, VerifyEmailComponent],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NgxSkeletonLoaderModule.forRoot(),
    NgcCookieConsentModule.forRoot(cookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    DatePipe /* CookieService */,
    ,
    CurrencyPipe,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthHeaderInterceptor,
        multi: true,
      },
    ],
    [MessagingService, AsyncPipe],
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
