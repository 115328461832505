import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AuthAPIService } from '@views/Auth/auth.service';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  messaging: any;
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private _authApiService: AuthAPIService,
    private _localStorageService: LocalStorageService
  ) {
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      this.messaging = _messaging;
      // _messaging.onMessage.subscribe((x) => {
      //   _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      // });
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log('token', token);
        if (token != null) {
          let userData: any = this._localStorageService.getData('userDetails');
          this._authApiService
            .saveFcmToken(token, 'USER_AGENT_' + userData?.id)
            .then((res: any) => {
              if (res) {
                console.log('saved token : ', res);
              }
            });
        }
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      console.log('new message received. ', payload);
      this.currentMessage.next(payload);
    });
  }

  listen() {
    const messaging = getMessaging();
    this.messaging.onMessage(messaging, (payload: any) => {
      console.log('Message received. ', payload);
    });
  }

  // getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
  //   if (currentToken){
  //     (async ()=>{
  //       let token_saved = await this._authService.saveUserFCMToken(currentToken);
  //     })();

  //     // Send the token to your server and update the UI if necessary
  //   } else {
  //     // Show permission request UI
  //     console.log('No registration token available. Request permission to generate one.');
  //   }
  // }).catch((err:any) => {
  //     console.log('An error occurred while retrieving token. ', err);
  // });

  // requestPermission() {
  //   this.angularFireMessaging.requestToken
  //     .subscribe(
  //       (token) => { console.log('Permission granted! Save to the server!', token); },
  //       (error) => { console.error(error); },
  //     );
  // }
}
function getMessaging() {
  throw new Error('Function not implemented.');
}
